import React, { Component } from "react";
import { Link } from "react-router-dom";
import SearchForm from "./../widgets/SearchForm";
import logo from "./../images/logo.png";
import facebook1 from "./../images/facebook-icon.svg";
import instagram1 from "./../images/instagram-icon.svg";
import outline1 from "./../images/outline-icon.svg";
import youtube1 from "./../images/youtube-icon.svg";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { HomeService } from "./../services/home";
import { dateFormat } from "./../config/common";

import "./../css/Header.css";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: [], 
      headerClass:"head-outer",
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll,{ passive: true });
    this.fetchCategory(); 
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  fetchCategory = async () => {
    const category = await HomeService.fetchCategory();
    this.setState({ category });
  };
  handleScroll = async (e) => {  
    const headerClass=window.scrollY>80?"head-outer stiky-header":"head-outer";
    this.setState({ headerClass });
  };
  handleSubmit = async (e) => {
    // e.preventDefault();
    console.log("Form submitted:");
  };
  render() {
    return (
      <>
        <div className={this.state.headerClass} >
          <div className="head-top">
            <div className=" container">
              <div className="head-top-row">
                <div className="head-top-left">
                  <p>{ dateFormat(new Date(),"M d, Y") }</p>
                </div>
                <div className="head-top-right">
                  <ul className="social-ul">
                    <li>
                      <Link to="#">
                        <img src={facebook1} alt="logo" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <img src={instagram1} alt="logo" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        {" "}
                        <img src={outline1} alt="logo" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <img src={youtube1} alt="logo" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="head-wrap">
            <div className=" container">
              <div className="head-nav">
                <Navbar expand="lg" className="bg-body-tertiary">
                  <Navbar.Brand href="/">
                    <img src={logo} alt="logo" />
                  </Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className=" ">
                      <Nav.Link href="/">Home</Nav.Link>
                      <Nav.Link href="/products">Products</Nav.Link>
                      <Nav.Link href="/contac">Contact Us</Nav.Link>
                    </Nav>
                  </Navbar.Collapse>

                  <Nav className="  login-ul">
                    <Nav.Link href="/login">Login</Nav.Link>
                    <Nav.Link href="/signup">Sign Up</Nav.Link>
                  </Nav>
                </Navbar>
              </div>
            </div>
          </div>
          <div className="search-wrap">
            <div className=" container">
              <SearchForm
                formClass="top-serch"
                placeholder={"Search News"}
                handleSubmit={this.handleSubmit}
                categorys={this.state.headerClass==="head-outer"? this.state.category:null}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Header;
