import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import Slider from "react-slick";

import "./../css/widget/TrendingNewsSlider.css";


class TrendingNewsSlider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            news: props.news,
            tag: props.tag
        };
    }
    static getDerivedStateFromProps(props, state) {
        return {
            news: props.news,
            tag: props.tag
        };
    }
    render() {
        return (
            <>
                <Card className="news-block">
                    <Card.Body>
                        <div className={"trend-news"}  >
                            <span className="hd">{this.state.tag}</span>
                            <div className="marquee">
                                <div className="marquee-wrap">
                                    {this.state.news ?
                                        this.state.news.map((item) => {
                                            return (
                                                <Link className="trend-txt" to={"/news/" + item.slug} key={"news-block-" + item.id}>

                                                    <div className="trending">

                                                        <span>{item.title}</span>

                                                    </div>

                                                </Link>
                                            );
                                        }) : (
                                            <></>
                                        )}
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card >
            </>

        )
    }
}

export default TrendingNewsSlider;