import React, { Component } from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";

import "./../css/widget/OneBlockAdCard.css";

class OneBlockAdCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ads: props.ads,
    };
  }
  static getDerivedStateFromProps(props, state) {
    return {
      ads: props.ads,
    };
  }
  render() {
    return (
      <>
        {this.state.ads ? (
          <Link to={this.state.ads.link}>
            <Card className="ads-block">
              <Card.Body>
                <div className="news-img">
                  <Card.Img
                    src={this.state.ads.image}
                    alt={this.state.ads.title}
                  />
                </div>
              </Card.Body>
            </Card>
          </Link>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default OneBlockAdCard;
