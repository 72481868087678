import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./templates/Layout";
import Home from "./templates/Home";
import Contact from "./templates/Contact";
import News from "./templates/News";
import NewsContent from "./templates/NewsContent";
import Search from "./templates/Search";
import NoPage from "./templates/NoPage";


import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
 
function App() {
  return (
    <BrowserRouter  >
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/search" element={<Search />} />
          <Route path="/news/:catslug" element={<News />} />
          <Route path="/:catslug/:newsslug" element={<NewsContent />} /> 
          <Route path="*" element={<NoPage />} />
      </Route>
      </Routes>
    </BrowserRouter>
  );
}



export default App;
