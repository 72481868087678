import React, { Component } from "react";
import SearchForm from "./../widgets/SearchForm";
import BrandSlider from "../widgets/BrandSlider";
import NavTabBar from "../widgets/NavTabBar";
import SideBarAdCard from "../widgets/SideBarAdCard";
import NewsCardSlider from "../widgets/NewsCardSlider";
import TrendingNewsSlider from "../widgets/TrendingNewsSlider";
import LatestNewsCard from "../widgets/LatestNewsCard";
import NewsSlider from "../widgets/NewsSlider";
import NewsListSlider from "../widgets/NewsListSlider";

import { HomeService } from "./../services/home";
 
class HomeController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: [],
      products: { data: [] },
      ads: {
        sidebar: [],
      },
      news: {},
    };
  }
  componentDidMount() {
    this.fetchProducts();
    this.fetchCategory();
    this.fetchAds();
    this.fetchPost();
  }
  fetchProducts = async () => {
    const products = await HomeService.fetchProducts();
    this.setState({ products });
  };
  fetchCategory = async () => {
    const category = await HomeService.fetchCategory();
    this.setState({ category });
  };
  fetchAds = async () => {
    const ads = await HomeService.fetchAds();
    this.setState({ ads });
  };
  fetchPost = async () => {
    const news = await HomeService.fetchPost();
    this.setState({ news });
  };
  handleSubmit = async (e) => {
    // e.preventDefault();
    console.log("Form submitted:"); 
  };
  render() {
    return (
      <div className="home-page-outer">
        <div className="brand-wrp">
          <div className="slider-wrap slide-desk">
            <BrandSlider products={this.state.products.data} ismobile={false}></BrandSlider>
          </div>
          
          <div className="slider-wrap slide-mob">
            <BrandSlider products={this.state.products.data} ismobile={true}></BrandSlider>
          </div>
        </div>
        <div className="row main-menu">
          <div className="col-md-9">
            <NavTabBar categorys={this.state.category}></NavTabBar>
          </div>
          <div className="col-md-3">
            <div className="search-news">
              <SearchForm
                placeholder={"Search News"}
                handleSubmit={this.handleSubmit}
              />
            </div>
          </div>
        </div>
        <div className="row row-two">
          <div className="col-md-9">
            <div className="row  split-row row-one">
              <div className=" col-md-6 block-col1 ">
                <TrendingNewsSlider
                  news={this.state.news.trending}
                  tag={"TRENDING NOW"}
                ></TrendingNewsSlider>
                <NewsCardSlider news={this.state.news.latest}></NewsCardSlider>
              </div>
              <div className=" col-md-6 block-col2  ">
                <LatestNewsCard news={this.state.news.latest} ></LatestNewsCard> 
              </div>  
            </div>
            {/* <NewsSlider news={this.state.news.list} ads={this.state.ads } ></NewsSlider>   */}
            <NewsListSlider news={this.state.news.category} ads={this.state.ads } ></NewsListSlider>
          </div>
          <div className="col-md-3">
            <SideBarAdCard ads={this.state.ads.sidebar}></SideBarAdCard>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeController;
