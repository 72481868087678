import {  Link } from "react-router-dom";
// import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import facebook1 from './../images/facebook-icon.svg';
import instagram1 from './../images/instagram-icon.svg';
import outline1 from './../images/outline-icon.svg';
import youtube1 from './../images/youtube-icon.svg';
const Footer = () => {
    return (
      <>
       <div className="footer-wrap">
       <div className="container">
       <div className="row ftr-nav">
            <div className="col-md-6 m-auto social-ul"> 
              <Navbar expand="lg" className="bg-body-tertiary">
                    <Nav className=" ">
                        <Nav.Link href="/"><img src={facebook1} alt="logo"/></Nav.Link>
                        <Nav.Link href="/"><img src={instagram1} alt="logo"/></Nav.Link>
                        <Nav.Link href="/"><img src={outline1} alt="logo"/></Nav.Link>
                        <Nav.Link href="/"><img src={youtube1} alt="logo"/></Nav.Link>
                    </Nav>
                </Navbar>
            </div>
         </div>

         <div className="row copyright">
            <div className="col-md-6 m-auto  "> 
            <p>Copyright © 2024  <Link href="#home">Dailylawtimes.</Link> All Rights Reserved</p>
            </div>
        </div>
         </div>
         </div>
      </>
    )
};

export default Footer;