import React, { Component } from "react"; 
import NewsSliderAddRow from "../widgets/NewsSliderAddRow"; 
import NewsSliderRow from "../widgets/NewsSliderRow"; 

import "./../css/widget/NewsSlider.css";

class NewsSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: props.news,
      tags: props.news
        ? Object.keys(props.news)
        : [],
      ads: props.ads,
    };
  }
  static getDerivedStateFromProps(props, state) {
    return {
      news: props.news,
      tags: props.news
        ? Object.keys(props.news)
        : [],
      ads: props.ads,
    };
  }
  render() {
    return (
      <>
      {this.state.tags.filter((itm,idx)=>{
        return idx%2===0
      }).map((item, idx) => {
        return (<div className="news-row-group" key={"split-row-news-" + item}>
            { idx % 3 === 0 && this.state.ads.onecol &&
                this.state.ads.onecol.length > 0 &&
                this.state.ads.onecol.length > parseInt(idx / 3)?(
                  <NewsSliderAddRow news1={this.state.news[item]}  item1={item} item2={ this.state.tags[(idx*2)+1]? this.state.tags[(idx*2)+1]:item}  news2={ this.state.tags[(idx*2)+1]? this.state.news[ this.state.tags[(idx*2)+1]]:[]} ads={this.state.ads.onecol[parseInt(idx / 3)]} />
                ):(
                  <NewsSliderRow news1={this.state.news[item]} item1={item}  item2={ this.state.tags[(idx*2)+1]? this.state.tags[(idx*2)+1]:item}  news2={ this.state.tags[(idx*2)+1]? this.state.news[ this.state.tags[(idx*2)+1]]:[]} />
                )}
        </div>);
      })}
 
      </>
    );
  }
}

export default NewsSlider;
