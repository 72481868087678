import { Outlet } from "react-router-dom";
import Footer from './Footer.js';
import Header from './Header.js';

const Layout = () => {
  return (
    <> 
      <Header></Header>
      <div className="body-container">
        <div className="container">
          <Outlet />
        </div>
      </div>
      <Footer></Footer>
    </>

  )
};

export default Layout;
