import React, { Component } from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Slider from "react-slick";

import noimage from "./../images/dailytime-noimg.png";

import "./../css/widget/NewsCardSlider.css";

class NewsCardSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: props.news,
      tag: props.tag,
    };
  }
  static getDerivedStateFromProps(props, state) {
    return {
      news: props.news,
      tag: props.tag,
    };
  }
  render() {
    return (
      <>
        {this.state.news ? (
          <Slider
            className={"news-slider"}
            infinite={true}
            slidesToShow={1}
            slidesToScroll={1}
            speed={500}
            autoplay={true}
            autoplaySpeed={2000}
          >
            {this.state.news.map((item) => {
              return (
                <Link to={"/latest/" + item.slug} key={"news-block-" + item.id}>
                  <Card className="news-block">
                    <Card.Body>
                      <div className="news-img">
                        <Card.Img
                          src={item.image ? item.image : noimage}
                          alt=""
                        />
                      </div>
                      <Card.Title className="news-content">
                        {/* <p>The Times of India</p> */}
                        <h2>{item.title}</h2>
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Link>
              );
            })}
          </Slider>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default NewsCardSlider;
