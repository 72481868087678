import React, { Component } from "react";

import SearchForm from "./../widgets/SearchForm";
import BrandSlider from "../widgets/BrandSlider";
import NavTabBar from "../widgets/NavTabBar";
import SideBarAdCard from "../widgets/SideBarAdCard";
import SearchCard from "../widgets/SearchCard";

import { SearchService } from "./../services/search";

class SearchController extends Component {
  constructor(props) {
    super(props); 
    this.state = {
      category: [],
      products: { data: [] },
      ads: {
        sidebar: [],
      },
      news: [],
      pagination:{
        page: 1,
        pageSize: 25,
        pageCount: 1,
        total: 0
      },
      ...props,
    };
  }
  componentDidMount() {
    this.fetchProducts();
    this.fetchCategory();
    this.fetchAds();
    this.fetchPostBySearch();
  }
  fetchProducts = async () => {
    const products = await SearchService.fetchProducts();
    this.setState({ products });
  };
  fetchCategory = async () => {
    const category = await SearchService.fetchCategory();
    this.setState({ category });
  };
  fetchAds = async () => {
    const ads = await SearchService.fetchAds();
    this.setState({ ads });
  };

  fetchPostBySearch = async () => {
    const {news,pagination} = await SearchService.fetchPostBySearch(this.state.q,this.state.c);
    this.setState({ news,pagination });
  };
  handleSubmit = async (e) => {
    // e.preventDefault();
    console.log("Form submitted:");
  };
  render() {
    return (
      <div className="home-page-outer">
        <div className="brand-wrp">
        <div className="slider-wrap slide-desk">
            <BrandSlider products={this.state.products.data} ismobile={false}></BrandSlider>
          </div>
          <div className="slider-wrap slide-mob">
            <BrandSlider products={this.state.products.data} ismobile={true}></BrandSlider>
          </div>
        </div>
        <div className="row main-menu">
          <div className="col-md-9">
            <NavTabBar categorys={this.state.category}></NavTabBar>
          </div>
          <div className="col-md-3">
            <div className="search-news">
              <SearchForm
                placeholder={"Search News"}
                handleSubmit={this.handleSubmit}
              />
            </div>
          </div>
        </div>
        <div className="row row-two">
          <div className="col-md-9"> 
          <div className={"row  split-row row-three search-row"} >
            {
                this.state.news.map((news,idx)=>{
                return <SearchCard news={news} key={"search-card-"+idx} /> })
            } 
          </div>
          </div>
          <div className="col-md-3">
            <SideBarAdCard ads={this.state.ads.sidebar}></SideBarAdCard>
          </div>
        </div>
      </div>
    );
  }
}
export default SearchController;
