import React, { Component } from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Slider from "react-slick";
import OneBlockAdCard from "../widgets/OneBlockAdCard"; 

import { dateFormat } from "./../config/common";

import noimage from "./../images/dailytime-noimg.png";

class NewsSliderAddRow extends Component {
    constructor(props) {
      super(props);
      if(props.news1.length>props.news2){

        this.state = {
            news1: props.news2,
            news2: props.news1,
            ads: props.ads,
            item2: props.item1,
            item1: props.item2,
          };
      }else{

        this.state = {
            news1: props.news1,
            news2: props.news2,
            ads: props.ads,
            item2: props.item2,
            item1: props.item1,
        };
      }
    }
    static getDerivedStateFromProps(props, state) {
        if(props.news1.length>props.news2){
            return {
                news1: props.news2,
                news2: props.news1,
                ads: props.ads,
                item2: props.item1,
                item1: props.item2,
            };

        }else{
            return {
              news1: props.news1, 
              news2: props.news2, 
              ads: props.ads,
              item2: props.item2,
              item1: props.item1,
            };

        }
    }
    render() {
        return (
            <>
                <div className="row  split-row row-two">
                    <div className=" col-md-6 block-col1 ">
                        <OneBlockAdCard ads={ this.state.ads } ></OneBlockAdCard>
                    </div>
                    <div className=" col-md-6 block-col2">
                      <div className="latest-wrap latest-wrap-row ">
                        <span className="latest-txt">
                          {this.state.news1[0]
                            ? this.state.news1[0].category
                            : ""}
                        </span>
                        <div className="news-slider2-wrap  ">
                          <Slider
                            className={ this.state.news1.length > 2 ? "news-slider2" : "news-slider2 no-slide" }
                            infinite={false}
                            slidesToShow={1}
                            slidesToScroll={1}
                          >
                            {this.state.news1
                              .filter((news, nid) => {
                                return nid % 2 === 0;
                              })
                              .map((newsd, nid) => {
                                return  (
                                  <Card
                                    className="latest-block"
                                    key={
                                      "split-row-news-" +
                                      this.state.item1 +
                                      "latest-block" +
                                      newsd.id
                                    }
                                  >
                                    <Card.Body>
                                      <div className="latest-block">
                                        <div className="news-list-left">
                                          <span className="latest-name">
                                            {newsd.category}
                                          </span>
                                          <div className="latest-img">
                                            <img
                                              src={
                                                newsd.image
                                                  ? newsd.image
                                                  : noimage
                                              }
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                        <div className="news-list-right">
                                          <div className="news-list-top">
                                            {/* <span className="list-top-lft">
                                              The Times of India
                                            </span> */}
                                            <span className="list-top-rgt">
                                              {dateFormat(
                                                newsd.publish_date,
                                                "d M Y"
                                              )}
                                            </span>
                                          </div>
                                          <Link
                                            to={"/" + this.state.item1 + "/" + newsd.slug}
                                          >
                                            <h2>{newsd.title}</h2>
                                            <div
                                              className="content"
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  newsd
                                                    .content,
                                              }}
                                            ></div>
                                          </Link>
                                          <div className="news-list-bottom">
                                            <span className="list-btm-lft">
                                            <span className="viewcount" >{newsd.views_count}</span>
                                              {/* <span className="like">55</span>
                                              <span className="dislike"></span> */}
                                            </span>
                                            <span className="list-btm-rgt"></span>
                                          </div>
                                        </div>
                                      </div>
                                      {this.state.news1[(nid * 2) + 1] ? (
                                        <div className="latest-block">
                                          <div className="news-list-left">
                                            <span className="latest-name">
                                              {
                                                this.state.news1[
                                                  (nid * 2) + 1
                                                ].category
                                              }
                                            </span>
                                            <div className="latest-img">
                                              <img
                                                src={
                                                  this.state.news1[
                                                    (nid * 2) + 1
                                                  ].image
                                                    ? this.state.news1[
                                                        (nid * 2) + 1
                                                      ].image
                                                    : noimage
                                                }
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                          <div className="news-list-right">
                                            <div className="news-list-top">
                                              {/* <span className="list-top-lft">
                                                The Times of India
                                              </span> */}
                                              <span className="list-top-rgt">
                                                {dateFormat(
                                                  this.state.news1[
                                                    (nid * 2) + 1
                                                  ].publish_date,
                                                  "d M Y"
                                                )}
                                              </span>
                                            </div>
                                            <Link
                                              to={
                                                "/" +
                                                this.state.item1 +
                                                "/" +
                                                this.state.news1[
                                                  (nid * 2) + 1
                                                ].slug
                                              }
                                            >
                                              <h2>
                                                {
                                                  this.state.news1[
                                                    (nid * 2) + 1
                                                  ].title
                                                }
                                              </h2>
                                              <div
                                                className="content"
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    this.state.news1[
                                                      (nid * 2) + 1
                                                    ].content,
                                                }}
                                              ></div>
                                            </Link>
                                            <div className="news-list-bottom">
                                              <span className="list-btm-lft">
                                              <span className="viewcount" >{this.state.news1[ (nid * 2) + 1 ].views_count}</span>
                                                {/* <span className="like">55</span>
                                                <span className="dislike"></span> */}
                                              </span>
                                              <span className="list-btm-rgt">
                                                {" "}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )}

                                      <Link
                                        to={"/news/" + this.state.item1}
                                        className="seemore"
                                      >
                                        {" "}
                                        See More{" "}
                                      </Link>
                                    </Card.Body>
                                  </Card>
                                );
                              })}
                              {
                                this.state.news2.length < 3?(
                                    <>
                                    {this.state.news2
                              .filter((news, nid) => {
                                return nid % 2 === 0;
                              })
                              .map((newsd, nid) => {
                                return  (
                                  <Card
                                    className="latest-block"
                                    key={
                                      "split-row-news-" +
                                      this.state.item2 +
                                      "latest-block" +
                                      newsd.id
                                    }
                                  >
                                    <Card.Body>
                                      <div className="latest-block">
                                        <div className="news-list-left">
                                          <span className="latest-name">
                                            {newsd.category}
                                          </span>
                                          <div className="latest-img">
                                            <img
                                              src={
                                                newsd.image
                                                  ? newsd.image
                                                  : noimage
                                              }
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                        <div className="news-list-right">
                                          <div className="news-list-top">
                                            {/* <span className="list-top-lft">
                                              The Times of India
                                            </span> */}
                                            <span className="list-top-rgt">
                                              {dateFormat(
                                                newsd.publish_date,
                                                "d M Y"
                                              )}
                                            </span>
                                          </div>
                                          <Link
                                            to={"/" + this.state.item2 + "/" + newsd.slug}
                                          >
                                            <h2>{newsd.title}</h2>
                                            <div
                                              className="content"
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  newsd
                                                    .content,
                                              }}
                                            ></div>
                                          </Link>
                                          <div className="news-list-bottom">
                                            <span className="list-btm-lft">
                                            <span className="viewcount" >{newsd.views_count}</span>
                                              {/* <span className="like">55</span>
                                              <span className="dislike"></span> */}
                                            </span>
                                            <span className="list-btm-rgt"></span>
                                          </div>
                                        </div>
                                      </div>
                                      {this.state.news2[(nid * 2) + 1] ? (
                                        <div className="latest-block">
                                          <div className="news-list-left">
                                            <span className="latest-name">
                                              {
                                                this.state.news2[
                                                  (nid * 2) + 1
                                                ].category
                                              }
                                            </span>
                                            <div className="latest-img">
                                              <img
                                                src={
                                                  this.state.news2[
                                                    (nid * 2) + 1
                                                  ].image
                                                    ? this.state.news2[
                                                        (nid * 2) + 1
                                                      ].image
                                                    : noimage
                                                }
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                          <div className="news-list-right">
                                            <div className="news-list-top">
                                              {/* <span className="list-top-lft">
                                                The Times of India
                                              </span> */}
                                              <span className="list-top-rgt">
                                                {dateFormat(
                                                  this.state.news2[
                                                    (nid * 2) + 1
                                                  ].publish_date,
                                                  "d M Y"
                                                )}
                                              </span>
                                            </div>
                                            <Link
                                              to={
                                                "/" +
                                                this.state.item2 +
                                                "/" +
                                                this.state.news2[
                                                  (nid * 2) + 1
                                                ].slug
                                              }
                                            >
                                              <h2>
                                                {
                                                  this.state.news2[
                                                    (nid * 2) + 1
                                                  ].title
                                                }
                                              </h2>
                                              <div
                                                className="content"
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    this.state.news2[
                                                      (nid * 2) + 1
                                                    ].content,
                                                }}
                                              ></div>
                                            </Link>
                                            <div className="news-list-bottom">
                                              <span className="list-btm-lft">
                                              <span className="viewcount" >{this.state.news2[ (nid * 2) + 1 ].views_count}</span>
                                                {/* <span className="like">55</span>
                                                <span className="dislike"></span> */}
                                              </span>
                                              <span className="list-btm-rgt">
                                                {" "}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )}

                                      <Link
                                        to={"/news/" + this.state.item2}
                                        className="seemore"
                                      >
                                        {" "}
                                        See More{" "}
                                      </Link>
                                    </Card.Body>
                                  </Card>
                                );
                              })}
                                    
                                    </>
                                ):(<></>)
                              }
                          </Slider>
                        </div>
                      </div>
                    </div>
                </div>
                {
                    this.state.news2.length >= 3?(
                        <div className="row  split-row row-three">
              <div className=" col-md-6 block-col1 ">
                <div className="news-wrap-block">
                  <span className="latest-txt">
                    {this.state.news2[0].category}
                  </span>
                  <Card className="latest-block">
                    <Card.Body>
                      <div className="latest-block">
                        <div className="news-list-img">
                          {this.state.news2[0].subcategory ? (
                            <span className="latest-name">
                              {this.state.news2[0].subcategory}
                            </span>
                          ) : (
                            <></>
                          )}
                          <div className="latest-img">
                            <img
                              src={
                                this.state.news2[0].image
                                  ? this.state.news2[0].image
                                  : noimage
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="news-list-cnt">
                          <div className="news-list-top">
                            {/* <span className="list-top-lft">
                                                The Times of India
                                                </span> */}
                            <span className="list-top-rgt">
                              {dateFormat(
                                this.state.news2[0].publish_date,
                                "d M Y"
                              )}
                            </span>
                          </div>

                          <Link
                            to={"/" + this.state.item2 + "/" + this.state.news2[0].slug}
                          >
                            <h2>{this.state.news2[0].title}</h2>
                            <div
                              className="content"
                              dangerouslySetInnerHTML={{
                                __html: this.state.news2[0].content,
                              }}
                            ></div>
                          </Link>
                          <div className="news-list-bottom">
                            <span className="list-btm-lft">
                              <span className="viewcount">
                                {this.state.news2[0].views_count}
                              </span>
                              {/* <span className="like">55</span>
                                                <span className="dislike"></span> */}
                            </span>
                            <span className="list-btm-rgt"> </span>
                          </div>
                        </div>
                      </div>
                      {this.state.news2[1] ? (
                        <div className="latest-block">
                          <div className="news-list-img">
                            {this.state.news2[1].subcategory ? (
                              <span className="latest-name">
                                {this.state.news2[1].subcategory}
                              </span>
                            ) : (
                              <></>
                            )}
                            <div className="latest-img">
                              <img
                                src={
                                  this.state.news2[1].image
                                    ? this.state.news2[1].image
                                    : noimage
                                }
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="news-list-cnt">
                            <div className="news-list-top">
                              {/* <span className="list-top-lft">
                                                    The Times of India
                                                </span> */}
                              <span className="list-top-rgt">
                                {dateFormat(
                                  this.state.news2[1].publish_date,
                                  "d M Y"
                                )}
                              </span>
                            </div>

                            <Link
                              to={"/" + this.state.item2 + "/" + this.state.news2[1].slug}
                            >
                              <h2>{this.state.news2[1].title}</h2>
                              <div
                                className="content"
                                dangerouslySetInnerHTML={{
                                  __html: this.state.news2[1].content,
                                }}
                              ></div>
                            </Link>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="viewcount">
                                  {this.state.news2[1].views_count}
                                </span>
                                {/* <span className="like">55</span>
                                                    <span className="dislike"></span> */}
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div className=" col-md-6 block-col2  ">
                <div className="latest-wrap latest-wrap-row  ">
                  {/* <span className="latest-txt">
                                        {this.state.news2[0].category}
                                    </span> */}
                  <div className="news-slider2-wrap  ">
                    <Slider
                      className={
                        this.state.news2.length > 2
                          ? "news-slider2"
                          : "news-slider2 no-slide"
                      }
                      infinite={false}
                      slidesToShow={1}
                      slidesToScroll={1}
                    >
                      {this.state.news2
                        .filter((i, k) => {
                          return k % 2 === 0;
                        })
                        .map((itm, nid) => {

                          return nid===0?(<></> ):(
                            <Card
                              className="latest-block"
                              key={"latest-op-block" + itm.id}
                            >
                              <Card.Body>
                                <div className="latest-block">
                                  <div className="news-list-left">
                                    {itm.subcategory ? (
                                      <span className="latest-name">
                                        {itm.subcategory}
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                    <div className="latest-img">
                                      <img
                                        src={itm.image ? itm.image : noimage}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div className="news-list-right">
                                    <div className="news-list-top">
                                      {/* <span className="list-top-lft">
                                                            The Times of India
                                                        </span> */}
                                      <span className="list-top-rgt">
                                        {dateFormat(itm.publish_date, "d M Y")}
                                      </span>
                                    </div>
                                    <Link to={"/" + this.state.item2 + "/" + itm.slug}>
                                      <h2>{itm.title}</h2>
                                      <div
                                        className="content"
                                        dangerouslySetInnerHTML={{
                                          __html: itm.content,
                                        }}
                                      ></div>
                                    </Link>
                                    <div className="news-list-bottom">
                                      <span className="list-btm-lft">
                                        <span className="viewcount">
                                          {itm.views_count}
                                        </span>
                                        {/* <span className="like">55</span>
                                                            <span className="dislike"></span> */}
                                      </span>
                                      <span className="list-btm-rgt"> </span>
                                    </div>
                                  </div>
                                </div>
                                {this.state.news2[nid * 2 + 1] ? (
                                  <div className="latest-block">
                                    <div className="news-list-left">
                                      {this.state.news2[nid * 2 + 1]
                                        .subcategory ? (
                                        <span className="latest-name">
                                          {
                                            this.state.news2[nid * 2 + 1]
                                              .subcategory
                                          }
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                      <div className="latest-img">
                                        <img
                                          src={
                                            this.state.news2[nid * 2 + 1].image
                                              ? this.state.news2[nid * 2 + 1]
                                                  .image
                                              : noimage
                                          }
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <div className="news-list-right">
                                      <div className="news-list-top">
                                        {/* <span className="list-top-lft">
                                                            The Times of India
                                                            </span> */}
                                        <span className="list-top-rgt">
                                          {dateFormat(
                                            this.state.news2[nid * 2 + 1]
                                              .publish_date,
                                            "d M Y"
                                          )}
                                        </span>
                                      </div>
                                      <Link
                                        to={
                                          "/" +
                                          this.state.item2 +
                                          "/" +
                                          this.state.news2[nid * 2 + 1].slug
                                        }
                                      >
                                        <h2>
                                          {this.state.news2[nid * 2 + 1].title}
                                        </h2>
                                        <div
                                          className="content"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              this.state.news2[nid * 2 + 1]
                                                .content,
                                          }}
                                        ></div>
                                      </Link>
                                      <div className="news-list-bottom">
                                        <span className="list-btm-lft">
                                          <span className="viewcount">
                                            {
                                              this.state.news2[nid * 2 + 1]
                                                .views_count
                                            }
                                          </span>
                                          {/* <span className="like">55</span>
                                                            <span className="dislike"></span> */}
                                        </span>
                                        <span className="list-btm-rgt"> </span>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}

                                <Link to={"/news/" + this.state.item2} className="seemore">
                                  {" "}
                                  See More{" "}
                                </Link>
                              </Card.Body>
                            </Card>
                          );
                        })}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
                    ):(
                        <>
                        
                        
                        </>
                    )
                }
            </>
        );
    }

}
export default NewsSliderAddRow;