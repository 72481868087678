// import { Outlet, Link } from "react-router-dom";
import "./../css/Home.css";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import product1 from './../images/product-img1.jpg';
// import product2 from './../images/product-img2.jpg';
// import product3 from './../images/product-img3.jpg';
// import product4 from './../images/product-img4.jpg';
// import product5 from './../images/product-img5.jpg';
// import product6 from './../images/product-img6.jpg';
// import product7 from './../images/product-img7.jpg';
// import newsslider1 from './../images/news-slider1.jpg';
// import latestimg1 from './../images/latest-img1.jpg';
// import latestimg2 from './../images/latest-img2.jpg';
// import reservimg1 from './../images/reserv-img1.jpg';
// import adsbg1 from './../images/ads-bg1.jpg';
// import adssidebarimg from './../images/ads-sidebar.jpg';
// import judgementimg1 from './../images/judgement-img1.jpg';
// import judgementimg2 from './../images/judgement-img2.jpg';
// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
// import Container from 'react-bootstrap/Container';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import Card from 'react-bootstrap/Card';

import HomeController from "./../controllers/HomeController";
const Home = () => {
    return <HomeController />;
/*
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    className: "product-slider",
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 414,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    className: "product-slider2",
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 414,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="home-page-outer">
      <Form className="search-form">
        <Form.Label className="custom-select">
          <Form.Select aria-label="Default select example">
            <option>All</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Select>
        </Form.Label>
        <Form.Control type="search" className="form-control" />
        <button value=" " className="search-btn"></button>
      </Form>

      <div className="brand-wrp">
        <div className="slider-wrap">
          <Slider
            className={"product-slider"}
            infinite={true}
            slidesToShow={7}
            slidesToScroll={1}
          >
            <div className="brand-list">
              {" "}
              <img src={product1} alt="" />
              <span className="brand-name">Product1</span>
            </div>
            <div className="brand-list">
              {" "}
              <img src={product2} alt="" />
              <span className="brand-name">Product2</span>
            </div>
            <div className="brand-list">
              {" "}
              <img src={product3} alt="" />
              <span className="brand-name">Product3</span>
            </div>
            <div className="brand-list">
              {" "}
              <img src={product4} alt="" />
              <span className="brand-name">Product4</span>
            </div>
            <div className="brand-list">
              {" "}
              <img src={product5} alt="" />
              <span className="brand-name">Product5</span>
            </div>
            <div className="brand-list">
              {" "}
              <img src={product6} alt="" />
              <span className="brand-name">Product6</span>
            </div>
            <div className="brand-list">
              {" "}
              <img src={product7} alt="" />
              <span className="brand-name">Product7</span>
            </div>
            <div className="brand-list">
              {" "}
              <img src={product1} alt="" />
              <span className="brand-name">Product1</span>
            </div>
            <div className="brand-list">
              {" "}
              <img src={product2} alt="" />
              <span className="brand-name">Product2</span>
            </div>
            <div className="brand-list">
              {" "}
              <img src={product3} alt="" />
              <span className="brand-name">Product3</span>
            </div>
            <div className="brand-list">
              {" "}
              <img src={product4} alt="" />
              <span className="brand-name">Product4</span>
            </div>
            <div className="brand-list">
              {" "}
              <img src={product5} alt="" />
              <span className="brand-name">Product5</span>
            </div>
            <div className="brand-list">
              {" "}
              <img src={product6} alt="" />
              <span className="brand-name">Product6</span>
            </div>
            <div className="brand-list">
              {" "}
              <img src={product7} alt="" />
              <span className="brand-name">Product7</span>
            </div>
          </Slider>
        </div>
      </div>
      <div className="row main-menu">
        <div className="col-md-9">
          <Navbar expand="lg" className="bg-body-tertiary">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className=" nav-menu">
                <Nav.Link href="#home">All</Nav.Link>
                <Nav.Link href="#link">News & Interviews</Nav.Link>
                <Nav.Link href="#link">Judgements</Nav.Link>
                <Nav.Link href="#link">Circulars & Notifications</Nav.Link>
                <Nav.Link href="#link">Rules & Regulations</Nav.Link>
                <Nav.Link href="#link">Articles</Nav.Link>
                <Nav.Link href="#link">More</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
        <div className="col-md-3">
          <div className="search-news">
            <Form className="search-form">
              <Form.Control
                type="search"
                className="form-control"
                placeholder="Search News"
              />
              <button value=" " className="search-btn"></button>
            </Form>
          </div>
        </div>
      </div>

      <div className="row row-two">
        <div className="col-md-9">
          <div className="row  split-row row-one">
            <div className=" col-md-6 block-col1 ">
              <Slider
                className={"news-slider"}
                infinite={true}
                slidesToShow={1}
                slidesToScroll={1}
              >
                <Card className="news-block">
                  <Card.Body>
                    <div className="trending">
                      <span className="hd">TRENDING NOW</span>
                      <p>Indian Cricket Team of to a great Start</p>
                    </div>
                    <div className="news-img">
                      <Card.Img src={newsslider1} alt="" />
                    </div>
                    <Card.Title className="news-content">
                      <p>The Times of India</p>
                      <h2>
                        IPL 2024 Opening Ceremony: Performers, Date, Time, Venue
                        - All You Need To Know
                      </h2>
                    </Card.Title>
                  </Card.Body>
                </Card>
                <Card className="news-block">
                  <Card.Body>
                    <div className="trending">
                      <span className="hd">TRENDING NOW</span>
                      <p>Indian Cricket Team of to a great Start</p>
                    </div>
                    <div className="news-img">
                      <Card.Img src={newsslider1} alt="" />
                    </div>
                    <Card.Title className="news-content">
                      <p>The Times of India</p>
                      <h2>
                        IPL 2024 Opening Ceremony: Performers, Date, Time, Venue
                        - All You Need To Know
                      </h2>
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Slider>
            </div>
            <div className=" col-md-6 block-col2  ">
              <div className="latest-wrap  ">
                <span className="latest-txt">LATEST NEWS</span>
                <Card className="latest-block">
                  <Card.Body>
                    <span>
                      Oscars 2024: Oppenheimer sweeps awards as Cillian
                    </span>
                    <div className="latest-img">
                      <img src={latestimg1} alt="" />
                    </div>
                  </Card.Body>
                </Card>
                <Card className="latest-block">
                  <Card.Body>
                    <span>
                      Oscars 2024: Oppenheimer sweeps awards as Cillian
                    </span>
                    <div className="latest-img">
                      <img src={latestimg2} alt="" />
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="news-list">
                <Card className="news-list-block">
                  <Card.Body>
                    <div className="news-list-top">
                      <span className="list-top-lft">The Times of India</span>
                      <span className="list-top-rgt">22 Mar 2024</span>
                    </div>
                    <h2>Oscars 2024: Oppenheimer sweeps awards as Cillian</h2>
                    <h4>
                      All right Well take care youself i guess thats what you...
                    </h4>
                    <div className="news-list-bottom">
                      <span className="list-btm-lft">
                        <span className="like">55</span>
                        <span className="dislike"></span>
                      </span>
                      <span className="list-btm-rgt"> </span>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="news-list-block">
                  <Card.Body>
                    <div className="news-list-top">
                      <span className="list-top-lft">The Times of India</span>
                      <span className="list-top-rgt">22 Mar 2024</span>
                    </div>
                    <h2>Oscars 2024: Oppenheimer sweeps awards as Cillian</h2>
                    <h4>
                      All right Well take care youself i guess thats what you...
                    </h4>
                    <div className="news-list-bottom">
                      <span className="list-btm-lft">
                        <span className="like">55</span>
                        <span className="dislike"></span>
                      </span>
                      <span className="list-btm-rgt"> </span>
                    </div>
                  </Card.Body>
                </Card>
                <Link to="#" className="seemore">
                  {" "}
                  See More{" "}
                </Link>
              </div>
            </div>
          </div>
          <div className="row  split-row row-two">
            <div className=" col-md-6 block-col1 ">
              <Card className="ads-block">
                <Card.Body>
                  <div className="news-img">
                    <Card.Img src={adsbg1} alt="" />
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className=" col-md-6 block-col2  ">
              <div className="latest-wrap  ">
                <span className="latest-txt">LATEST NEWS</span>
                <div className="news-slider2-wrap  ">
                  <Slider
                    className={"news-slider2"}
                    infinite={true}
                    slidesToShow={1}
                    slidesToScroll={1}
                  >
                    <Card className="latest-block">
                      <Card.Body>
                        <div className="latest-block">
                          <div className="news-list-left">
                            <span className="latest-name">Movies</span>
                            <div className="latest-img">
                              <img src={latestimg1} alt="" />
                            </div>
                          </div>
                          <div className="news-list-right">
                            <div className="news-list-top">
                              <span className="list-top-lft">
                                The Times of India
                              </span>
                              <span className="list-top-rgt">22 Mar 2024</span>
                            </div>
                            <h2>
                              Oscars 2024: Oppenheimer sweeps awards as Cillian
                            </h2>
                            <h4>
                              All right Well take care youself i guess thats
                              what you...
                            </h4>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="like">55</span>
                                <span className="dislike"></span>
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                        <div className="latest-block">
                          <div className="news-list-left">
                            <span className="latest-name">Movies</span>
                            <div className="latest-img">
                              <img src={latestimg2} alt="" />
                            </div>
                          </div>
                          <div className="news-list-right">
                            <div className="news-list-top">
                              <span className="list-top-lft">
                                The Times of India
                              </span>
                              <span className="list-top-rgt">22 Mar 2024</span>
                            </div>
                            <h2>
                              Oscars 2024: Oppenheimer sweeps awards as Cillian
                            </h2>
                            <h4>
                              All right Well take care youself i guess thats
                              what you...
                            </h4>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="like">55</span>
                                <span className="dislike"></span>
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                        <Link to="#" className="seemore">
                          {" "}
                          See More{" "}
                        </Link>
                      </Card.Body>
                    </Card>
                    <Card className="latest-block">
                      <Card.Body>
                        <div className="latest-block">
                          <div className="news-list-left">
                            <span className="latest-name">Movies</span>
                            <div className="latest-img">
                              <img src={latestimg1} alt="" />
                            </div>
                          </div>
                          <div className="news-list-right">
                            <div className="news-list-top">
                              <span className="list-top-lft">
                                The Times of India
                              </span>
                              <span className="list-top-rgt">22 Mar 2024</span>
                            </div>
                            <h2>
                              Oscars 2024: Oppenheimer sweeps awards as Cillian
                            </h2>
                            <h4>
                              All right Well take care youself i guess thats
                              what you...
                            </h4>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="like">55</span>
                                <span className="dislike"></span>
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                        <div className="latest-block">
                          <div className="news-list-left">
                            <span className="latest-name">Movies</span>
                            <div className="latest-img">
                              <img src={latestimg2} alt="" />
                            </div>
                          </div>
                          <div className="news-list-right">
                            <div className="news-list-top">
                              <span className="list-top-lft">
                                The Times of India
                              </span>
                              <span className="list-top-rgt">22 Mar 2024</span>
                            </div>
                            <h2>
                              Oscars 2024: Oppenheimer sweeps awards as Cillian
                            </h2>
                            <h4>
                              All right Well take care youself i guess thats
                              what you...
                            </h4>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="like">55</span>
                                <span className="dislike"></span>
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                        <Link to="#" className="seemore">
                          {" "}
                          See More{" "}
                        </Link>
                      </Card.Body>
                    </Card>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
          <div className="row  split-row row-three">
            <div className=" col-md-6 block-col1 ">
              <div className="news-wrap-block">
                <span class="latest-txt">JUDGEMENTS</span>
                <Card className="latest-block">
                  <Card.Body>
                    <div className="latest-block">
                      <div className="news-list-img">
                        <span className="latest-name">Movies</span>
                        <div className="latest-img">
                          <img src={latestimg1} alt="" />
                        </div>
                      </div>
                      <div className="news-list-cnt">
                        <div className="news-list-top">
                          <span className="list-top-lft">
                            The Times of India
                          </span>
                          <span className="list-top-rgt">22 Mar 2024</span>
                        </div>
                        <h2>
                          Oscars 2024: Oppenheimer sweeps awards as Cillian
                        </h2>
                        <h4>
                          All right Well take care youself i guess thats what
                          you...
                        </h4>
                        <div className="news-list-bottom">
                          <span className="list-btm-lft">
                            <span className="like">55</span>
                            <span className="dislike"></span>
                          </span>
                          <span className="list-btm-rgt"> </span>
                        </div>
                      </div>
                    </div>
                    <div className="latest-block">
                      <div className="news-list-img">
                        <span className="latest-name">Movies</span>
                        <div className="latest-img">
                          <img src={latestimg2} alt="" />
                        </div>
                      </div>
                      <div className="news-list-cnt">
                        <div className="news-list-top">
                          <span className="list-top-lft">
                            The Times of India
                          </span>
                          <span className="list-top-rgt">22 Mar 2024</span>
                        </div>
                        <h2>
                          Oscars 2024: Oppenheimer sweeps awards as Cillian
                        </h2>
                        <h4>
                          All right Well take care youself i guess thats what
                          you...
                        </h4>
                        <div className="news-list-bottom">
                          <span className="list-btm-lft">
                            <span className="like">55</span>
                            <span className="dislike"></span>
                          </span>
                          <span className="list-btm-rgt"> </span>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div className=" col-md-6 block-col2  ">
              <div className="latest-wrap  ">
                <span className="latest-txt">LATEST NEWS</span>
                <div className="news-slider2-wrap  ">
                  <Slider
                    className={"news-slider2"}
                    infinite={true}
                    slidesToShow={1}
                    slidesToScroll={1}
                  >
                    <Card className="latest-block">
                      <Card.Body>
                        <div className="latest-block">
                          <div className="news-list-left">
                            <span className="latest-name">Movies</span>
                            <div className="latest-img">
                              <img src={judgementimg1} alt="" />
                            </div>
                          </div>
                          <div className="news-list-right">
                            <div className="news-list-top">
                              <span className="list-top-lft">
                                The Times of India
                              </span>
                              <span className="list-top-rgt">22 Mar 2024</span>
                            </div>
                            <h2>
                              Oscars 2024: Oppenheimer sweeps awards as Cillian
                            </h2>
                            <h4>
                              All right Well take care youself i guess thats
                              what you...
                            </h4>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="like">55</span>
                                <span className="dislike"></span>
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                        <div className="latest-block">
                          <div className="news-list-left">
                            <span className="latest-name">Movies</span>
                            <div className="latest-img">
                              <img src={judgementimg2} alt="" />
                            </div>
                          </div>
                          <div className="news-list-right">
                            <div className="news-list-top">
                              <span className="list-top-lft">
                                The Times of India
                              </span>
                              <span className="list-top-rgt">22 Mar 2024</span>
                            </div>
                            <h2>
                              Oscars 2024: Oppenheimer sweeps awards as Cillian
                            </h2>
                            <h4>
                              All right Well take care youself i guess thats
                              what you...
                            </h4>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="like">55</span>
                                <span className="dislike"></span>
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                        <Link to="#" className="seemore">
                          {" "}
                          See More{" "}
                        </Link>
                      </Card.Body>
                    </Card>
                    <Card className="latest-block">
                      <Card.Body>
                        <div className="latest-block">
                          <div className="news-list-left">
                            <span className="latest-name">Movies</span>
                            <div className="latest-img">
                              <img src={latestimg1} alt="" />
                            </div>
                          </div>
                          <div className="news-list-right">
                            <div className="news-list-top">
                              <span className="list-top-lft">
                                The Times of India
                              </span>
                              <span className="list-top-rgt">22 Mar 2024</span>
                            </div>
                            <h2>
                              Oscars 2024: Oppenheimer sweeps awards as Cillian
                            </h2>
                            <h4>
                              All right Well take care youself i guess thats
                              what you...
                            </h4>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="like">55</span>
                                <span className="dislike"></span>
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                        <div className="latest-block">
                          <div className="news-list-left">
                            <span className="latest-name">Movies</span>
                            <div className="latest-img">
                              <img src={latestimg2} alt="" />
                            </div>
                          </div>
                          <div className="news-list-right">
                            <div className="news-list-top">
                              <span className="list-top-lft">
                                The Times of India
                              </span>
                              <span className="list-top-rgt">22 Mar 2024</span>
                            </div>
                            <h2>
                              Oscars 2024: Oppenheimer sweeps awards as Cillian
                            </h2>
                            <h4>
                              All right Well take care youself i guess thats
                              what you...
                            </h4>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="like">55</span>
                                <span className="dislike"></span>
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                        <Link to="#" className="seemore">
                          {" "}
                          See More{" "}
                        </Link>
                      </Card.Body>
                    </Card>
                  </Slider>
                </div>
              </div>
            </div>
          </div>

          <div className="row  split-row row-three">
            <div className=" col-md-6 block-col1 ">
              <div className="news-wrap-block">
                <span class="latest-txt">JUDGEMENTS</span>
                <Card className="latest-block">
                  <Card.Body>
                    <div className="latest-block">
                      <div className="news-list-img">
                        <span className="latest-name">Movies</span>
                        <div className="latest-img">
                          <img src={latestimg1} alt="" />
                        </div>
                      </div>
                      <div className="news-list-cnt">
                        <div className="news-list-top">
                          <span className="list-top-lft">
                            The Times of India
                          </span>
                          <span className="list-top-rgt">22 Mar 2024</span>
                        </div>
                        <h2>
                          Oscars 2024: Oppenheimer sweeps awards as Cillian
                        </h2>
                        <h4>
                          All right Well take care youself i guess thats what
                          you...
                        </h4>
                        <div className="news-list-bottom">
                          <span className="list-btm-lft">
                            <span className="like">55</span>
                            <span className="dislike"></span>
                          </span>
                          <span className="list-btm-rgt"> </span>
                        </div>
                      </div>
                    </div>
                    <div className="latest-block">
                      <div className="news-list-img">
                        <span className="latest-name">Movies</span>
                        <div className="latest-img">
                          <img src={latestimg2} alt="" />
                        </div>
                      </div>
                      <div className="news-list-cnt">
                        <div className="news-list-top">
                          <span className="list-top-lft">
                            The Times of India
                          </span>
                          <span className="list-top-rgt">22 Mar 2024</span>
                        </div>
                        <h2>
                          Oscars 2024: Oppenheimer sweeps awards as Cillian
                        </h2>
                        <h4>
                          All right Well take care youself i guess thats what
                          you...
                        </h4>
                        <div className="news-list-bottom">
                          <span className="list-btm-lft">
                            <span className="like">55</span>
                            <span className="dislike"></span>
                          </span>
                          <span className="list-btm-rgt"> </span>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div className=" col-md-6 block-col2  ">
              <div className="latest-wrap  ">
                <span className="latest-txt">LATEST NEWS</span>
                <div className="news-slider2-wrap  ">
                  <Slider
                    className={"news-slider2"}
                    infinite={true}
                    slidesToShow={1}
                    slidesToScroll={1}
                  >
                    <Card className="latest-block">
                      <Card.Body>
                        <div className="latest-block">
                          <div className="news-list-left">
                            <span className="latest-name">Movies</span>
                            <div className="latest-img">
                              <img src={judgementimg1} alt="" />
                            </div>
                          </div>
                          <div className="news-list-right">
                            <div className="news-list-top">
                              <span className="list-top-lft">
                                The Times of India
                              </span>
                              <span className="list-top-rgt">22 Mar 2024</span>
                            </div>
                            <h2>
                              Oscars 2024: Oppenheimer sweeps awards as Cillian
                            </h2>
                            <h4>
                              All right Well take care youself i guess thats
                              what you...
                            </h4>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="like">55</span>
                                <span className="dislike"></span>
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                        <div className="latest-block">
                          <div className="news-list-left">
                            <span className="latest-name">Movies</span>
                            <div className="latest-img">
                              <img src={judgementimg2} alt="" />
                            </div>
                          </div>
                          <div className="news-list-right">
                            <div className="news-list-top">
                              <span className="list-top-lft">
                                The Times of India
                              </span>
                              <span className="list-top-rgt">22 Mar 2024</span>
                            </div>
                            <h2>
                              Oscars 2024: Oppenheimer sweeps awards as Cillian
                            </h2>
                            <h4>
                              All right Well take care youself i guess thats
                              what you...
                            </h4>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="like">55</span>
                                <span className="dislike"></span>
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                        <Link to="#" className="seemore">
                          {" "}
                          See More{" "}
                        </Link>
                      </Card.Body>
                    </Card>
                    <Card className="latest-block">
                      <Card.Body>
                        <div className="latest-block">
                          <div className="news-list-left">
                            <span className="latest-name">Movies</span>
                            <div className="latest-img">
                              <img src={latestimg1} alt="" />
                            </div>
                          </div>
                          <div className="news-list-right">
                            <div className="news-list-top">
                              <span className="list-top-lft">
                                The Times of India
                              </span>
                              <span className="list-top-rgt">22 Mar 2024</span>
                            </div>
                            <h2>
                              Oscars 2024: Oppenheimer sweeps awards as Cillian
                            </h2>
                            <h4>
                              All right Well take care youself i guess thats
                              what you...
                            </h4>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="like">55</span>
                                <span className="dislike"></span>
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                        <div className="latest-block">
                          <div className="news-list-left">
                            <span className="latest-name">Movies</span>
                            <div className="latest-img">
                              <img src={latestimg2} alt="" />
                            </div>
                          </div>
                          <div className="news-list-right">
                            <div className="news-list-top">
                              <span className="list-top-lft">
                                The Times of India
                              </span>
                              <span className="list-top-rgt">22 Mar 2024</span>
                            </div>
                            <h2>
                              Oscars 2024: Oppenheimer sweeps awards as Cillian
                            </h2>
                            <h4>
                              All right Well take care youself i guess thats
                              what you...
                            </h4>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="like">55</span>
                                <span className="dislike"></span>
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                        <Link to="#" className="seemore">
                          {" "}
                          See More{" "}
                        </Link>
                      </Card.Body>
                    </Card>
                  </Slider>
                </div>
              </div>
            </div>
          </div>

          <div className="row  split-row row-three">
            <div className=" col-md-6 block-col1 ">
              <div className="news-wrap-block">
                <span class="latest-txt">JUDGEMENTS</span>
                <Card className="latest-block">
                  <Card.Body>
                    <div className="latest-block">
                      <div className="news-list-img">
                        <span className="latest-name">Movies</span>
                        <div className="latest-img">
                          <img src={latestimg1} alt="" />
                        </div>
                      </div>
                      <div className="news-list-cnt">
                        <div className="news-list-top">
                          <span className="list-top-lft">
                            The Times of India
                          </span>
                          <span className="list-top-rgt">22 Mar 2024</span>
                        </div>
                        <h2>
                          Oscars 2024: Oppenheimer sweeps awards as Cillian
                        </h2>
                        <h4>
                          All right Well take care youself i guess thats what
                          you...
                        </h4>
                        <div className="news-list-bottom">
                          <span className="list-btm-lft">
                            <span className="like">55</span>
                            <span className="dislike"></span>
                          </span>
                          <span className="list-btm-rgt"> </span>
                        </div>
                      </div>
                    </div>
                    <div className="latest-block">
                      <div className="news-list-img">
                        <span className="latest-name">Movies</span>
                        <div className="latest-img">
                          <img src={latestimg2} alt="" />
                        </div>
                      </div>
                      <div className="news-list-cnt">
                        <div className="news-list-top">
                          <span className="list-top-lft">
                            The Times of India
                          </span>
                          <span className="list-top-rgt">22 Mar 2024</span>
                        </div>
                        <h2>
                          Oscars 2024: Oppenheimer sweeps awards as Cillian
                        </h2>
                        <h4>
                          All right Well take care youself i guess thats what
                          you...
                        </h4>
                        <div className="news-list-bottom">
                          <span className="list-btm-lft">
                            <span className="like">55</span>
                            <span className="dislike"></span>
                          </span>
                          <span className="list-btm-rgt"> </span>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div className=" col-md-6 block-col2  ">
              <div className="latest-wrap  ">
                <span className="latest-txt">LATEST NEWS</span>
                <div className="news-slider2-wrap  ">
                  <Slider
                    className={"news-slider2"}
                    infinite={true}
                    slidesToShow={1}
                    slidesToScroll={1}
                  >
                    <Card className="latest-block">
                      <Card.Body>
                        <div className="latest-block">
                          <div className="news-list-left">
                            <span className="latest-name">Movies</span>
                            <div className="latest-img">
                              <img src={judgementimg1} alt="" />
                            </div>
                          </div>
                          <div className="news-list-right">
                            <div className="news-list-top">
                              <span className="list-top-lft">
                                The Times of India
                              </span>
                              <span className="list-top-rgt">22 Mar 2024</span>
                            </div>
                            <h2>
                              Oscars 2024: Oppenheimer sweeps awards as Cillian
                            </h2>
                            <h4>
                              All right Well take care youself i guess thats
                              what you...
                            </h4>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="like">55</span>
                                <span className="dislike"></span>
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                        <div className="latest-block">
                          <div className="news-list-left">
                            <span className="latest-name">Movies</span>
                            <div className="latest-img">
                              <img src={judgementimg2} alt="" />
                            </div>
                          </div>
                          <div className="news-list-right">
                            <div className="news-list-top">
                              <span className="list-top-lft">
                                The Times of India
                              </span>
                              <span className="list-top-rgt">22 Mar 2024</span>
                            </div>
                            <h2>
                              Oscars 2024: Oppenheimer sweeps awards as Cillian
                            </h2>
                            <h4>
                              All right Well take care youself i guess thats
                              what you...
                            </h4>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="like">55</span>
                                <span className="dislike"></span>
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                        <Link to="#" className="seemore">
                          {" "}
                          See More{" "}
                        </Link>
                      </Card.Body>
                    </Card>
                    <Card className="latest-block">
                      <Card.Body>
                        <div className="latest-block">
                          <div className="news-list-left">
                            <span className="latest-name">Movies</span>
                            <div className="latest-img">
                              <img src={latestimg1} alt="" />
                            </div>
                          </div>
                          <div className="news-list-right">
                            <div className="news-list-top">
                              <span className="list-top-lft">
                                The Times of India
                              </span>
                              <span className="list-top-rgt">22 Mar 2024</span>
                            </div>
                            <h2>
                              Oscars 2024: Oppenheimer sweeps awards as Cillian
                            </h2>
                            <h4>
                              All right Well take care youself i guess thats
                              what you...
                            </h4>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="like">55</span>
                                <span className="dislike"></span>
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                        <div className="latest-block">
                          <div className="news-list-left">
                            <span className="latest-name">Movies</span>
                            <div className="latest-img">
                              <img src={latestimg2} alt="" />
                            </div>
                          </div>
                          <div className="news-list-right">
                            <div className="news-list-top">
                              <span className="list-top-lft">
                                The Times of India
                              </span>
                              <span className="list-top-rgt">22 Mar 2024</span>
                            </div>
                            <h2>
                              Oscars 2024: Oppenheimer sweeps awards as Cillian
                            </h2>
                            <h4>
                              All right Well take care youself i guess thats
                              what you...
                            </h4>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="like">55</span>
                                <span className="dislike"></span>
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                        <Link to="#" className="seemore">
                          {" "}
                          See More{" "}
                        </Link>
                      </Card.Body>
                    </Card>
                  </Slider>
                </div>
              </div>
            </div>
          </div>

          <div className="row  split-row row-three">
            <div className=" col-md-6 block-col1 ">
              <div className="news-wrap-block">
                <span class="latest-txt">JUDGEMENTS</span>
                <Card className="latest-block">
                  <Card.Body>
                    <div className="latest-block">
                      <div className="news-list-img">
                        <span className="latest-name">Movies</span>
                        <div className="latest-img">
                          <img src={latestimg1} alt="" />
                        </div>
                      </div>
                      <div className="news-list-cnt">
                        <div className="news-list-top">
                          <span className="list-top-lft">
                            The Times of India
                          </span>
                          <span className="list-top-rgt">22 Mar 2024</span>
                        </div>
                        <h2>
                          Oscars 2024: Oppenheimer sweeps awards as Cillian
                        </h2>
                        <h4>
                          All right Well take care youself i guess thats what
                          you...
                        </h4>
                        <div className="news-list-bottom">
                          <span className="list-btm-lft">
                            <span className="like">55</span>
                            <span className="dislike"></span>
                          </span>
                          <span className="list-btm-rgt"> </span>
                        </div>
                      </div>
                    </div>
                    <div className="latest-block">
                      <div className="news-list-img">
                        <span className="latest-name">Movies</span>
                        <div className="latest-img">
                          <img src={latestimg2} alt="" />
                        </div>
                      </div>
                      <div className="news-list-cnt">
                        <div className="news-list-top">
                          <span className="list-top-lft">
                            The Times of India
                          </span>
                          <span className="list-top-rgt">22 Mar 2024</span>
                        </div>
                        <h2>
                          Oscars 2024: Oppenheimer sweeps awards as Cillian
                        </h2>
                        <h4>
                          All right Well take care youself i guess thats what
                          you...
                        </h4>
                        <div className="news-list-bottom">
                          <span className="list-btm-lft">
                            <span className="like">55</span>
                            <span className="dislike"></span>
                          </span>
                          <span className="list-btm-rgt"> </span>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div className=" col-md-6 block-col2  ">
              <div className="latest-wrap  ">
                <span className="latest-txt">LATEST NEWS</span>
                <div className="news-slider2-wrap  ">
                  <Slider
                    className={"news-slider2"}
                    infinite={true}
                    slidesToShow={1}
                    slidesToScroll={1}
                  >
                    <Card className="latest-block">
                      <Card.Body>
                        <div className="latest-block">
                          <div className="news-list-left">
                            <span className="latest-name">Movies</span>
                            <div className="latest-img">
                              <img src={judgementimg1} alt="" />
                            </div>
                          </div>
                          <div className="news-list-right">
                            <div className="news-list-top">
                              <span className="list-top-lft">
                                The Times of India
                              </span>
                              <span className="list-top-rgt">22 Mar 2024</span>
                            </div>
                            <h2>
                              Oscars 2024: Oppenheimer sweeps awards as Cillian
                            </h2>
                            <h4>
                              All right Well take care youself i guess thats
                              what you...
                            </h4>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="like">55</span>
                                <span className="dislike"></span>
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                        <div className="latest-block">
                          <div className="news-list-left">
                            <span className="latest-name">Movies</span>
                            <div className="latest-img">
                              <img src={judgementimg2} alt="" />
                            </div>
                          </div>
                          <div className="news-list-right">
                            <div className="news-list-top">
                              <span className="list-top-lft">
                                The Times of India
                              </span>
                              <span className="list-top-rgt">22 Mar 2024</span>
                            </div>
                            <h2>
                              Oscars 2024: Oppenheimer sweeps awards as Cillian
                            </h2>
                            <h4>
                              All right Well take care youself i guess thats
                              what you...
                            </h4>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="like">55</span>
                                <span className="dislike"></span>
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                        <Link to="#" className="seemore">
                          {" "}
                          See More{" "}
                        </Link>
                      </Card.Body>
                    </Card>
                    <Card className="latest-block">
                      <Card.Body>
                        <div className="latest-block">
                          <div className="news-list-left">
                            <span className="latest-name">Movies</span>
                            <div className="latest-img">
                              <img src={latestimg1} alt="" />
                            </div>
                          </div>
                          <div className="news-list-right">
                            <div className="news-list-top">
                              <span className="list-top-lft">
                                The Times of India
                              </span>
                              <span className="list-top-rgt">22 Mar 2024</span>
                            </div>
                            <h2>
                              Oscars 2024: Oppenheimer sweeps awards as Cillian
                            </h2>
                            <h4>
                              All right Well take care youself i guess thats
                              what you...
                            </h4>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="like">55</span>
                                <span className="dislike"></span>
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                        <div className="latest-block">
                          <div className="news-list-left">
                            <span className="latest-name">Movies</span>
                            <div className="latest-img">
                              <img src={latestimg2} alt="" />
                            </div>
                          </div>
                          <div className="news-list-right">
                            <div className="news-list-top">
                              <span className="list-top-lft">
                                The Times of India
                              </span>
                              <span className="list-top-rgt">22 Mar 2024</span>
                            </div>
                            <h2>
                              Oscars 2024: Oppenheimer sweeps awards as Cillian
                            </h2>
                            <h4>
                              All right Well take care youself i guess thats
                              what you...
                            </h4>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="like">55</span>
                                <span className="dislike"></span>
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                        <Link to="#" className="seemore">
                          {" "}
                          See More{" "}
                        </Link>
                      </Card.Body>
                    </Card>
                  </Slider>
                </div>
              </div>
            </div>
          </div>

          <div className="row  split-row row-three">
            <div className=" col-md-6 block-col1 ">
              <div className="news-wrap-block">
                <span class="latest-txt">JUDGEMENTS</span>
                <Card className="latest-block">
                  <Card.Body>
                    <div className="latest-block">
                      <div className="news-list-img">
                        <span className="latest-name">Movies</span>
                        <div className="latest-img">
                          <img src={latestimg1} alt="" />
                        </div>
                      </div>
                      <div className="news-list-cnt">
                        <div className="news-list-top">
                          <span className="list-top-lft">
                            The Times of India
                          </span>
                          <span className="list-top-rgt">22 Mar 2024</span>
                        </div>
                        <h2>
                          Oscars 2024: Oppenheimer sweeps awards as Cillian
                        </h2>
                        <h4>
                          All right Well take care youself i guess thats what
                          you...
                        </h4>
                        <div className="news-list-bottom">
                          <span className="list-btm-lft">
                            <span className="like">55</span>
                            <span className="dislike"></span>
                          </span>
                          <span className="list-btm-rgt"> </span>
                        </div>
                      </div>
                    </div>
                    <div className="latest-block">
                      <div className="news-list-img">
                        <span className="latest-name">Movies</span>
                        <div className="latest-img">
                          <img src={latestimg2} alt="" />
                        </div>
                      </div>
                      <div className="news-list-cnt">
                        <div className="news-list-top">
                          <span className="list-top-lft">
                            The Times of India
                          </span>
                          <span className="list-top-rgt">22 Mar 2024</span>
                        </div>
                        <h2>
                          Oscars 2024: Oppenheimer sweeps awards as Cillian
                        </h2>
                        <h4>
                          All right Well take care youself i guess thats what
                          you...
                        </h4>
                        <div className="news-list-bottom">
                          <span className="list-btm-lft">
                            <span className="like">55</span>
                            <span className="dislike"></span>
                          </span>
                          <span className="list-btm-rgt"> </span>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div className=" col-md-6 block-col2  ">
              <div className="latest-wrap  ">
                <span className="latest-txt">LATEST NEWS</span>
                <div className="news-slider2-wrap  ">
                  <Slider
                    className={"news-slider2"}
                    infinite={true}
                    slidesToShow={1}
                    slidesToScroll={1}
                  >
                    <Card className="latest-block">
                      <Card.Body>
                        <div className="latest-block">
                          <div className="news-list-left">
                            <span className="latest-name">Movies</span>
                            <div className="latest-img">
                              <img src={judgementimg1} alt="" />
                            </div>
                          </div>
                          <div className="news-list-right">
                            <div className="news-list-top">
                              <span className="list-top-lft">
                                The Times of India
                              </span>
                              <span className="list-top-rgt">22 Mar 2024</span>
                            </div>
                            <h2>
                              Oscars 2024: Oppenheimer sweeps awards as Cillian
                            </h2>
                            <h4>
                              All right Well take care youself i guess thats
                              what you...
                            </h4>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="like">55</span>
                                <span className="dislike"></span>
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                        <div className="latest-block">
                          <div className="news-list-left">
                            <span className="latest-name">Movies</span>
                            <div className="latest-img">
                              <img src={judgementimg2} alt="" />
                            </div>
                          </div>
                          <div className="news-list-right">
                            <div className="news-list-top">
                              <span className="list-top-lft">
                                The Times of India
                              </span>
                              <span className="list-top-rgt">22 Mar 2024</span>
                            </div>
                            <h2>
                              Oscars 2024: Oppenheimer sweeps awards as Cillian
                            </h2>
                            <h4>
                              All right Well take care youself i guess thats
                              what you...
                            </h4>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="like">55</span>
                                <span className="dislike"></span>
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                        <Link to="#" className="seemore">
                          {" "}
                          See More{" "}
                        </Link>
                      </Card.Body>
                    </Card>
                    <Card className="latest-block">
                      <Card.Body>
                        <div className="latest-block">
                          <div className="news-list-left">
                            <span className="latest-name">Movies</span>
                            <div className="latest-img">
                              <img src={latestimg1} alt="" />
                            </div>
                          </div>
                          <div className="news-list-right">
                            <div className="news-list-top">
                              <span className="list-top-lft">
                                The Times of India
                              </span>
                              <span className="list-top-rgt">22 Mar 2024</span>
                            </div>
                            <h2>
                              Oscars 2024: Oppenheimer sweeps awards as Cillian
                            </h2>
                            <h4>
                              All right Well take care youself i guess thats
                              what you...
                            </h4>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="like">55</span>
                                <span className="dislike"></span>
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                        <div className="latest-block">
                          <div className="news-list-left">
                            <span className="latest-name">Movies</span>
                            <div className="latest-img">
                              <img src={latestimg2} alt="" />
                            </div>
                          </div>
                          <div className="news-list-right">
                            <div className="news-list-top">
                              <span className="list-top-lft">
                                The Times of India
                              </span>
                              <span className="list-top-rgt">22 Mar 2024</span>
                            </div>
                            <h2>
                              Oscars 2024: Oppenheimer sweeps awards as Cillian
                            </h2>
                            <h4>
                              All right Well take care youself i guess thats
                              what you...
                            </h4>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="like">55</span>
                                <span className="dislike"></span>
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                        <Link to="#" className="seemore">
                          {" "}
                          See More{" "}
                        </Link>
                      </Card.Body>
                    </Card>
                  </Slider>
                </div>
              </div>
            </div>
          </div>

          <div className="row  split-row row-three">
            <div className=" col-md-6 block-col1 ">
              <div className="news-wrap-block">
                <span class="latest-txt">JUDGEMENTS</span>
                <Card className="latest-block">
                  <Card.Body>
                    <div className="latest-block">
                      <div className="news-list-img">
                        <span className="latest-name">Movies</span>
                        <div className="latest-img">
                          <img src={latestimg1} alt="" />
                        </div>
                      </div>
                      <div className="news-list-cnt">
                        <div className="news-list-top">
                          <span className="list-top-lft">
                            The Times of India
                          </span>
                          <span className="list-top-rgt">22 Mar 2024</span>
                        </div>
                        <h2>
                          Oscars 2024: Oppenheimer sweeps awards as Cillian
                        </h2>
                        <h4>
                          All right Well take care youself i guess thats what
                          you...
                        </h4>
                        <div className="news-list-bottom">
                          <span className="list-btm-lft">
                            <span className="like">55</span>
                            <span className="dislike"></span>
                          </span>
                          <span className="list-btm-rgt"> </span>
                        </div>
                      </div>
                    </div>
                    <div className="latest-block">
                      <div className="news-list-img">
                        <span className="latest-name">Movies</span>
                        <div className="latest-img">
                          <img src={latestimg2} alt="" />
                        </div>
                      </div>
                      <div className="news-list-cnt">
                        <div className="news-list-top">
                          <span className="list-top-lft">
                            The Times of India
                          </span>
                          <span className="list-top-rgt">22 Mar 2024</span>
                        </div>
                        <h2>
                          Oscars 2024: Oppenheimer sweeps awards as Cillian
                        </h2>
                        <h4>
                          All right Well take care youself i guess thats what
                          you...
                        </h4>
                        <div className="news-list-bottom">
                          <span className="list-btm-lft">
                            <span className="like">55</span>
                            <span className="dislike"></span>
                          </span>
                          <span className="list-btm-rgt"> </span>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div className=" col-md-6 block-col2  ">
              <div className="latest-wrap  ">
                <span className="latest-txt">LATEST NEWS</span>
                <div className="news-slider2-wrap  ">
                  <Slider
                    className={"news-slider2"}
                    infinite={true}
                    slidesToShow={1}
                    slidesToScroll={1}
                  >
                    <Card className="latest-block">
                      <Card.Body>
                        <div className="latest-block">
                          <div className="news-list-left">
                            <span className="latest-name">Movies</span>
                            <div className="latest-img">
                              <img src={judgementimg1} alt="" />
                            </div>
                          </div>
                          <div className="news-list-right">
                            <div className="news-list-top">
                              <span className="list-top-lft">
                                The Times of India
                              </span>
                              <span className="list-top-rgt">22 Mar 2024</span>
                            </div>
                            <h2>
                              Oscars 2024: Oppenheimer sweeps awards as Cillian
                            </h2>
                            <h4>
                              All right Well take care youself i guess thats
                              what you...
                            </h4>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="like">55</span>
                                <span className="dislike"></span>
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                        <div className="latest-block">
                          <div className="news-list-left">
                            <span className="latest-name">Movies</span>
                            <div className="latest-img">
                              <img src={judgementimg2} alt="" />
                            </div>
                          </div>
                          <div className="news-list-right">
                            <div className="news-list-top">
                              <span className="list-top-lft">
                                The Times of India
                              </span>
                              <span className="list-top-rgt">22 Mar 2024</span>
                            </div>
                            <h2>
                              Oscars 2024: Oppenheimer sweeps awards as Cillian
                            </h2>
                            <h4>
                              All right Well take care youself i guess thats
                              what you...
                            </h4>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="like">55</span>
                                <span className="dislike"></span>
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                        <Link to="#" className="seemore">
                          {" "}
                          See More{" "}
                        </Link>
                      </Card.Body>
                    </Card>
                    <Card className="latest-block">
                      <Card.Body>
                        <div className="latest-block">
                          <div className="news-list-left">
                            <span className="latest-name">Movies</span>
                            <div className="latest-img">
                              <img src={latestimg1} alt="" />
                            </div>
                          </div>
                          <div className="news-list-right">
                            <div className="news-list-top">
                              <span className="list-top-lft">
                                The Times of India
                              </span>
                              <span className="list-top-rgt">22 Mar 2024</span>
                            </div>
                            <h2>
                              Oscars 2024: Oppenheimer sweeps awards as Cillian
                            </h2>
                            <h4>
                              All right Well take care youself i guess thats
                              what you...
                            </h4>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="like">55</span>
                                <span className="dislike"></span>
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                        <div className="latest-block">
                          <div className="news-list-left">
                            <span className="latest-name">Movies</span>
                            <div className="latest-img">
                              <img src={latestimg2} alt="" />
                            </div>
                          </div>
                          <div className="news-list-right">
                            <div className="news-list-top">
                              <span className="list-top-lft">
                                The Times of India
                              </span>
                              <span className="list-top-rgt">22 Mar 2024</span>
                            </div>
                            <h2>
                              Oscars 2024: Oppenheimer sweeps awards as Cillian
                            </h2>
                            <h4>
                              All right Well take care youself i guess thats
                              what you...
                            </h4>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="like">55</span>
                                <span className="dislike"></span>
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                        <Link to="#" className="seemore">
                          {" "}
                          See More{" "}
                        </Link>
                      </Card.Body>
                    </Card>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <Card className="block-col3">
            <Link to="#">
              <Card.Img src={reservimg1} alt="" />
            </Link>
          </Card>
          <Card className="block-col3">
            <Link to="#">
              <Card.Img src={adssidebarimg} alt="" />
            </Link>
          </Card>
        </div>
      </div>
    </div>
  );*/
};

export default Home;
