
import { request } from "./../config/request"
import { API_ENDPOINT } from "./../config/constant"


export const NewsService = {
    async fetchProducts() {        
        const products=await request.get('/api/products?populate=*').then((response)=>{
            let proData=[];
            let pagination=null;
            if(response.status===200){

                for (let pro in response.data.data) {
                    proData.push({
                        id:response.data.data[pro].id,
                        title:response.data.data[pro].attributes.title,
                        link:response.data.data[pro].attributes.link,
                        image:API_ENDPOINT+response.data.data[pro].attributes.image.data.attributes.url,
                    })
                }
                pagination=response.data.meta.pagination;
            }

            return {
                data:proData,
                pagination:pagination
            };
        }).catch ((error) =>{
            return { data:[], error:error };
        })        
        return products;
    },
    async fetchCategory() {        
        const products=await request.get('/api/categories?filters[parent_category][id][$null]=true&filters[posts][id][$notNull]=true').then((response)=>{
            let proData=[];
            if(response.status===200){
                for (let pro in response.data.data) {
                    proData.push({
                        id:response.data.data[pro].id,
                        title:response.data.data[pro].attributes.name,
                        slug:response.data.data[pro].attributes.slug,
                    })
                }
            }
            return proData;
        }).catch ((error) =>{
            return [];
        })        
        return products;
    },    
    async fetchAds() {        
        const ads=await request.get('/api/site-ads?populate=*').then((response)=>{
            let proData={
                sidebar:[],
                onecol:[],
                twocol:[]
            };
            if(response.status===200){
                for (let pro in response.data.data) {
                    switch (response.data.data[pro].attributes.type) {
                        case "Side Bar":                            
                                proData.sidebar.push({
                                    id:response.data.data[pro].id,
                                    title:response.data.data[pro].attributes.title,
                                    youtube:response.data.data[pro].attributes.youtube,
                                    link:response.data.data[pro].attributes.link,
                                    image:response.data.data[pro].attributes.image.data?API_ENDPOINT+response.data.data[pro].attributes.image.data.attributes.url:null,
                                })
                            break;                        
                        case "One Column into List":                            
                                proData.onecol.push({
                                    id:response.data.data[pro].id,
                                    title:response.data.data[pro].attributes.title,
                                    youtube:response.data.data[pro].attributes.youtube,
                                    link:response.data.data[pro].attributes.link,
                                    image:response.data.data[pro].attributes.image.data?API_ENDPOINT+response.data.data[pro].attributes.image.data.attributes.url:null,
                                })
                            break;
                                                      
                        case "Two Column into List":                            
                                proData.twocol.push({
                                    id:response.data.data[pro].id,
                                    title:response.data.data[pro].attributes.title,
                                    youtube:response.data.data[pro].attributes.youtube,
                                    link:response.data.data[pro].attributes.link,
                                    image:response.data.data[pro].attributes.image.data?API_ENDPOINT+response.data.data[pro].attributes.image.data.attributes.url:null,
                                })
                            break;
                    
                        default:
                                                        
                                proData.sidebar.push({
                                    id:response.data.data[pro].id,
                                    title:response.data.data[pro].attributes.title,
                                    youtube:response.data.data[pro].attributes.youtube,
                                    link:response.data.data[pro].attributes.link,
                                    image:response.data.data[pro].attributes.image.data?API_ENDPOINT+response.data.data[pro].attributes.image.data.attributes.url:null,
                                })
                            break;
                    }
                }
            }
            return proData;
        }).catch ((error) =>{
            console.log(error)
            return {
                sidebar:[],
                onecol:[],
                twocol:[]
            };
        })        
        return ads;
    },
    async fetchPostBySlug(slug){ 
        const posts=await request.get(`/api/posts?populate[image]=true&populate[users_permissions_user]=true&populate[tags]=*&populate[categories][populate][0]=parent_category&filters[slug][$eq]=${slug}&sort[0]=publish_date:desc`).then((response)=>{
            let proData={};
            if(response.status===200&&response.data.data&&response.data.data[0]){
                proData=response.data.data[0].attributes;
                proData.image=proData.image.data?API_ENDPOINT+proData.image.data.attributes.url:null
                proData.id=response.data.data[0].id; 
                proData.user=proData.users_permissions_user.data.attributes
                proData.user.id=proData.users_permissions_user.data.id
            }
            return proData;
        }).catch ((error) =>{ 
            return {};
        })  
        return posts;
    },
    async fetchPostByCategory(slug) {
        let apiLink = `/api/posts?pagination[pageSize]=100&populate[image]=*&populate[categories][populate][0]=parent_category&sort[0]=publish_date:desc`;
        if(slug!=="latest"&&slug!=="all"){
            if(slug==="trending"){
                apiLink = `/api/posts?populate[image]=*&populate[categories][populate][0]=parent_category&filters[trending][$eq]=true&sort[0]=publish_date:desc`;
            }else{
                apiLink = `/api/posts?populate[image]=*&populate[categories][populate][0]=parent_category&filters[$or][0][categories][slug][$contains]=${slug}&filters[$or][1][categories][parent_category][slug][$contains]=${slug}&sort[0]=publish_date:desc`;
            }
        }
        const posts=await request.get(apiLink).then((response)=>{
            let proData={   };
            if(response.status===200){
                for (let pro in response.data.data) {
                    const news=response.data.data[pro].attributes;
                    news.image=news.image.data?API_ENDPOINT+news.image.data.attributes.url:null
                    news.id=response.data.data[pro].id; 

                    if(response.data.data[pro].attributes.categories&&response.data.data[pro].attributes.categories.data){
                        for (const key in response.data.data[pro].attributes.categories.data) {
                            const cat = response.data.data[pro].attributes.categories.data[key];
                            news.category="";
                            news.subcategory="";
                            if(!proData[`${cat.attributes.slug}`]){
                                proData[`${cat.attributes.slug}`]=[];
                            }
                            if(cat.attributes.parent_category&&cat.attributes.parent_category.data){ 
                                news.category=cat.attributes.parent_category.data.attributes.name
                                news.subcategory=cat.attributes.name 
                            }else{
                                news.category=cat.attributes.name
                            }
                            proData[`${cat.attributes.slug}`].push(news);
                        } 
                    } 
                }
            } 
            return proData;
        }).catch ((error) =>{ 
            return {};
        })  
        return posts;
    },    
};