import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { API_ENDPOINT,API_KEY } from "./constant";

export const request = axios.create({
    baseURL: API_ENDPOINT,
    withCredentials: true,
    xsrfCookieName: 'csrf_access_token',
    headers:{  
        'Content-Type': 'application/json',
        'Accept': 'application/json', 
        'Authorization':"Bearer "+API_KEY
    }
})


request.interceptors.response.use((response) => {
    return response
}, async (error) => { 
    switch (error.response.status) {
        case 401:
            useNavigate('/');
            break;
        case 404:
            // router.push('/404');
            break;
        default:
                break;
    }
    return error.response;
});
