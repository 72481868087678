import React, { Component } from 'react';
import Nav from 'react-bootstrap/Nav';
import Slider from "react-slick";
import Navbar from 'react-bootstrap/Navbar';

import "./../css/widget/NavTabBar.css";

class NavTabBar extends Component{
    constructor(props) {
        super(props);
        this.state = {
            categorys: props.categorys,
            navid: "category-navbar-nav",
            navmenu:"nav-menu",
            isMore:true,
        };        
    }       
    static getDerivedStateFromProps(props, state) {
        return {
            categorys: props.categorys,
        };
    }
    handleMoreClick = (e) =>  {
        e.preventDefault();
        let isMore=this.state.isMore?false:true;
        let navmenu=isMore?"nav-menu":"nav-menu showmore";
        this.setState({ isMore ,navmenu });
    }
    render() {
        return (     
            <Navbar expand="lg" className="bg-body-tertiary">
                <Navbar.Toggle aria-controls={ this.state.navid } />
                <Navbar.Collapse className="basic-navbar-nav" id={ this.state.navid } >
                    <Nav className={ this.state.navmenu } >
                        <Nav.Link href="/news/all">All</Nav.Link>
                        <div className="navbar-slider-outer"  > 
                        <Slider className="nav-slider " 
                        responsive={ [

                            {
                              breakpoint: 767,
                              settings: {
                                slidesToShow: 5,
                                slidesToScroll: 1,
                              },
                            },
                            {
                              breakpoint: 600,
                              settings: {
                                slidesToShow: 3,
                                slidesToScroll: 1,
                              },
                            },
                            {
                              breakpoint: 414,
                              settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1,
                              },
                            },
                          ]
                        }
                        infinite={true} slidesToShow={7} slidesToScroll={3} variableWidth={true}  centerMode={true} > 
                        {
                            this.state.categorys.map((cat)=>{
                                return (
                                   
                                        <Nav.Link href={"/news/"+cat.slug} className="morenav" key={cat.slug} >{cat.title}</Nav.Link>
                                    
                                );
                                
                            })
                            
                        }
                        </Slider>
                        </div>
                        {/* <Nav.Link onClick={ this.handleMoreClick } className="more-link" >{  this.state.isMore?('More'):("Less") }</Nav.Link> */}
                        
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}
 

export default NavTabBar;