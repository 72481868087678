import React, { Component } from "react";
import { Link } from "react-router-dom";
import SearchForm from "./../widgets/SearchForm";
import NavTabBar from "../widgets/NavTabBar";
import BrandSlider from "../widgets/BrandSlider";
import facebook1 from "./../images/facebook-icon.svg";
import instagram1 from "./../images/instagram-icon.svg";
import outline1 from "./../images/outline-icon.svg";
import youtube1 from "./../images/youtube-icon.svg";
import Card from "react-bootstrap/Card";
import SideBarAdCard from "../widgets/SideBarAdCard";
import { NewsService } from "./../services/news";

import { dateFormat } from "./../config/common";
class NewsDetailController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: [],
      products: {
        data: [],
      },
      ads: {
        sidebar: [],
      },
      newsdata: {},
      ...props,
    };
  }
  componentDidMount() {
    this.fetchProducts();
    this.fetchCategory();
    this.fetchAds();
    this.fetchPostBySlug();
  }
  fetchProducts = async () => {
    const products = await NewsService.fetchProducts();
    this.setState({ products });
  };
  fetchAds = async () => {
    const ads = await NewsService.fetchAds();
    this.setState({ ads });
  };
  fetchCategory = async () => {
    const category = await NewsService.fetchCategory();
    this.setState({ category });
  };
  fetchPostBySlug = async () => {
    const slug = this.state.newsslug;
    const newsdata = await NewsService.fetchPostBySlug(slug);
    this.setState({ newsdata });
  };

  handleSubmit = async (e) => {
    // e.preventDefault();
    console.log("Form submitted:");
  };
  render() {
    console.log(this.state.newsdata)
    return (
      <div className="news-dtl-wrap">
        <div className="brand-wrp">
        <div className="slider-wrap slide-desk">
            <BrandSlider products={this.state.products.data} ismobile={false}></BrandSlider>
          </div>
          <div className="slider-wrap slide-mob">
            <BrandSlider products={this.state.products.data} ismobile={true}></BrandSlider>
          </div>
        </div>
        <div className="row main-menu">
          <div className="col-md-9">
            <NavTabBar categorys={this.state.category}></NavTabBar>
          </div>
          <div className="col-md-3">
            <div className="search-news">
              <SearchForm
                placeholder={"Search News"}
                handleSubmit={this.handleSubmit}
              />
            </div>
          </div>
        </div>
        <div className="row row-two ">
          <div className="col-md-9">
            <div className="news-dtl-col">
              <div className="news-dtl-head">
                {/* <h4>The Times of India</h4> */}
                <h1>{this.state.newsdata.title}</h1>
                <div className="newsdtl-ifo">
                  {this.state.newsdata.user?(<span className="sub">Story by {this.state.newsdata.user.first_name} {this.state.newsdata.user.last_name}</span>):(
                    <></>
                  )}
                  <span className="date">
                    {dateFormat(this.state.newsdata.publish_date, "d/m/Y")}
                  </span>
                  <span className="min">2 min read</span>
                </div>
                <ul className="social-newsdtl">
                  <li>
                    <Link to="#">
                      <img src={facebook1} alt="logo" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <img src={instagram1} alt="logo" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      {" "}
                      <img src={outline1} alt="logo" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <img src={youtube1} alt="logo" />
                    </Link>
                  </li>
                </ul>
              </div>
              {this.state.newsdata.image ? (
                <div className="newsdtl-img">
                  <Card.Img src={this.state.newsdata.image} alt="" />
                </div>
              ) : (
                <></>
              )}

              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.newsdata.content,
                }}
              ></div>
            </div>
          </div>
          <div className="col-md-3">
            <SideBarAdCard ads={this.state.ads.sidebar}></SideBarAdCard>
          </div>
        </div>
      </div>
    );
  }
}

export default NewsDetailController;
