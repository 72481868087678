import React, { Component } from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Slider from "react-slick";

import OneBlockAdCard from "../widgets/OneBlockAdCard";

import { dateFormat } from "./../config/common";
import noimage from "./../images/dailytime-noimg.png";

import "./../css/widget/NewsListSlider.css";

class NewsListSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: props.news,
      ads: props.ads,
    };
  }
  static getDerivedStateFromProps(props, state) {
    return {
      news: props.news,
      ads: props.ads,
    };
  }

  render() {
    const category = Object.keys(this.state.news ?? {});
    const elements = category.map((e, i) => {
      let addblock = <></>;
      if (
        i % 3 === 0 &&
        this.state.ads.onecol &&
        this.state.ads.onecol.length > 0 &&
        this.state.ads.onecol.length > parseInt(i / 3)
      ) {
        addblock = (
          <div className="col-md-6" key={i}>
            {" "}
            <div className="row split-row row-two">
              <div className="col-md-12 block-col1">
                <OneBlockAdCard
                  ads={this.state.ads.onecol[parseInt(i / 3)]}
                ></OneBlockAdCard>
              </div>
            </div>{" "}
          </div>
        );
      }
      let isnext = false;
      const subcategory = Object.keys(this.state.news[e] ?? {});
      let newsblocks = subcategory.sort((a,b)=>{
        const sorta = this.state.news[e][a].sort((x,y)=>{
            const xdate= new Date(this.state.news[e][a][x].publish_date)
            const ydate= new Date(this.state.news[e][a][y].publish_date)
            const xydif = xdate-ydate;
            if(xydif>0){
                return 1;
            }
            if(xydif<0){
                return -1;
            }
            return 0;
        });

        const sortb = this.state.news[e][b].sort((x,y)=>{
            const xdate= new Date(this.state.news[e][a][x].publish_date)
            const ydate= new Date(this.state.news[e][a][y].publish_date)
            const xydif = xdate-ydate;
            if(xydif>0){
                return 1;
            }
            if(xydif<0){
                return -1;
            }
            return 0;
        });

        const adate= new Date(sorta[0].publish_date)
        const bdate= new Date(sortb[0].publish_date)
        const abdif = adate-bdate;
        if(abdif>0){
            return 1;
        }
        if(abdif<0){
            return -1;
        }
        return 0;
      }).map((c, o) => {
        let nexblock = <></>;
        let currentnews = this.state.news[e][c][0];
        let nextnews = this.state.news[e][c][1];
        let newslist = this.state.news[e][c];
        let minlen = 2;
        let sliderblock = <></>;
        if (isnext) {
          currentnews = this.state.news[e][c][1];
          nextnews = this.state.news[e][c][2];
          let minlen = 3;
          isnext = false;
        }
        if (!currentnews) {
          return <></>;
        }
        if (!nextnews && subcategory[o + 1]) {
          nextnews = this.state.news[e][subcategory[o + 1]][0];
          isnext = true;
        }
        if (newslist.length > minlen+1) {
            const slidenews=this.state.news[e][c].slice(minlen, -1);
          sliderblock = (
            <div className="col-md-6">
              <div className="row split-row row-three">
                <div className=" col-md-12 block-col2  ">
                  <div className="latest-wrap latest-wrap-row  ">
                    <span className="latest-txt">
                        {slidenews[0].category}
                    </span>
                    <div className="news-slider2-wrap  ">
                      <Slider
                        className={
                            slidenews.length >  2
                            ? "news-slider2"
                            : "news-slider2 no-slide"
                        }
                        infinite={true}
                        slidesToShow={1}
                        slidesToScroll={1}
                      >
                        {
                          slidenews.filter((x, y) => {
                            return y % 2 === 0;
                          })
                          .map((news, y) => {
                            let nextslide=<></>
                            if(slidenews[(y*2)+1]){
                                nextslide=<div className="latest-block">
                                <div className="news-list-left">
                                  {slidenews[(y*2)+1].subcategory ? (
                                    <span className="latest-name">
                                      {slidenews[(y*2)+1].subcategory}
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                  <div className="latest-img">
                                    <img
                                      src={
                                        slidenews[(y*2)+1].image ? slidenews[(y*2)+1].image : noimage
                                      }
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div className="news-list-right">
                                  <div className="news-list-top">
                                    {/* <span className="list-top-lft">
                                                            The Times of India
                                                        </span> */}
                                    <span className="list-top-rgt">
                                      {dateFormat(
                                        slidenews[(y*2)+1].publish_date,
                                        "d M Y"
                                      )}
                                    </span>
                                  </div>
                                  <Link
                                    to={
                                      "/" +
                                      c +
                                      "/" +
                                      slidenews[(y*2)+1].slug
                                    }
                                  >
                                    <h2>{slidenews[(y*2)+1].title}</h2>
                                    <div
                                      className="content"
                                      dangerouslySetInnerHTML={{
                                        __html: slidenews[(y*2)+1].content,
                                      }}
                                    ></div>
                                  </Link>
                                  <div className="news-list-bottom">
                                    <span className="list-btm-lft">
                                      <span className="viewcount">
                                        {slidenews[(y*2)+1].views_count}
                                      </span>
                                      {/* <span className="like">55</span>
                                                            <span className="dislike"></span> */}
                                    </span>
                                    <span className="list-btm-rgt"> </span>
                                  </div>
                                </div>
                              </div>
                            }
                            return (
                              <Card
                                className="latest-block"
                                key={"latest-op-block" + news.id}
                              >
                                <Card.Body>
                                  <div className="latest-block">
                                    <div className="news-list-left">
                                      {news.subcategory ? (
                                        <span className="latest-name">
                                          {news.subcategory}
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                      <div className="latest-img">
                                        <img
                                          src={
                                            news.image ? news.image : noimage
                                          }
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <div className="news-list-right">
                                      <div className="news-list-top">
                                        {/* <span className="list-top-lft">
                                                                The Times of India
                                                            </span> */}
                                        <span className="list-top-rgt">
                                          {dateFormat(
                                            news.publish_date,
                                            "d M Y"
                                          )}
                                        </span>
                                      </div>
                                      <Link
                                        to={
                                          "/" +
                                          c +
                                          "/" +
                                          news.slug
                                        }
                                      >
                                        <h2>{news.title}</h2>
                                        <div
                                          className="content"
                                          dangerouslySetInnerHTML={{
                                            __html: news.content,
                                          }}
                                        ></div>
                                      </Link>
                                      <div className="news-list-bottom">
                                        <span className="list-btm-lft">
                                          <span className="viewcount">
                                            {news.views_count}
                                          </span>
                                          {/* <span className="like">55</span>
                                                                <span className="dislike"></span> */}
                                        </span>
                                        <span className="list-btm-rgt"> </span>
                                      </div>
                                    </div>
                                  </div>
                                  {nextslide }
                                </Card.Body>
                              </Card>
                            );
                          })}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
        if (nextnews) {
          nexblock = (
            <div className="latest-block">
              <div className="news-list-img">
                {nextnews.subcategory ? (
                  <span className="latest-name">{nextnews.subcategory}</span>
                ) : (
                  <></>
                )}
                <div className="latest-img">
                  <img src={nextnews.image ? nextnews.image : noimage} alt="" />
                </div>
              </div>
              <div className="news-list-cnt">
                <div className="news-list-top">
                  {/* <span className="list-top-lft">
                                    The Times of India
                                    </span> */}
                  <span className="list-top-rgt">
                    {dateFormat(nextnews.publish_date, "d M Y")}
                  </span>
                </div>

                <Link to={"/" + c + "/" + nextnews.slug}>
                  <h2>{nextnews.title}</h2>
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{
                      __html: nextnews.content,
                    }}
                  ></div>
                </Link>
                <div className="news-list-bottom">
                  <span className="list-btm-lft">
                    <span className="viewcount">{nextnews.views_count}</span>
                    {/* <span className="like">55</span>
                                    <span className="dislike"></span> */}
                  </span>
                  <span className="list-btm-rgt"> </span>
                </div>
              </div>
            </div>
          );
        }
        return (
          <>
            <div className="col-md-6" key={i + "-" + o}>
              <div className="row  split-row row-three">
                <div className="block-col1 col-md-12">
                  <div className="news-wrap-block">
                    <span className="latest-txt">{currentnews.category}</span>
                    <Card className="latest-block">
                      <Card.Body>
                        <div className="latest-block">
                          <div className="news-list-img">
                            {currentnews.subcategory ? (
                              <span className="latest-name">
                                {currentnews.subcategory}
                              </span>
                            ) : (
                              <></>
                            )}
                            <div className="latest-img">
                              <img
                                src={
                                  currentnews.image
                                    ? currentnews.image
                                    : noimage
                                }
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="news-list-cnt">
                            <div className="news-list-top">
                              {/* <span className="list-top-lft">
                                                The Times of India
                                                </span> */}
                              <span className="list-top-rgt">
                                {dateFormat(currentnews.publish_date, "d M Y")}
                              </span>
                            </div>

                            <Link
                              to={
                                "/" + c + "/" + currentnews.slug
                              }
                            >
                              <h2>{currentnews.title}</h2>
                              <div
                                className="content"
                                dangerouslySetInnerHTML={{
                                  __html: currentnews.content,
                                }}
                              ></div>
                            </Link>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="viewcount">
                                  {currentnews.views_count}
                                </span>
                                {/* <span className="like">55</span>
                                                <span className="dislike"></span> */}
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                        {nexblock}
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
            {sliderblock}
          </>
        );
      });
      return (
        <>
          {addblock}
          {newsblocks}
        </>
      );
    });

    return <div className="row">{elements}</div>;
  }
}

export default NewsListSlider;
