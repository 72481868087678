import React, { Component } from "react"; 
import SearchForm from "./../widgets/SearchForm";
import BrandSlider from "../widgets/BrandSlider";
import NavTabBar from "../widgets/NavTabBar";
import SideBarAdCard from "../widgets/SideBarAdCard"; 
import NewsSlider from "../widgets/NewsSlider";

import { NewsService } from "./../services/news";
 
class NewsController extends Component {
  constructor(props) {
    super(props); 
    this.state = {
      category: [],
      products: { data: [] },
      ads: {
        sidebar: [],
      },
      news: {},
      ...props
    };
  }
  componentDidMount() {  
      this.fetchProducts();
      this.fetchCategory();
      this.fetchAds();
      this.fetchPostByCategory(); 
  } 
  fetchProducts = async () => {
    const products = await NewsService.fetchProducts();
    this.setState({ products });
  };
  fetchCategory = async () => {
    const category = await NewsService.fetchCategory();
    this.setState({ category });
  };
  fetchAds = async () => {
    const {sidebar} = await NewsService.fetchAds();
    const ads= {
      sidebar:sidebar
    }
    this.setState({ ads });
  };
  fetchPostByCategory = async () => { 
    const  slug  = this.state.catslug;  
    const news = await NewsService.fetchPostByCategory(slug);
    this.setState({ news });
  };
  handleSubmit = async (e) => {
    // e.preventDefault();
    console.log("Form submitted:");
  };
  render() {
    return (
      <div className="home-page-outer">
        <div className="brand-wrp">
        <div className="slider-wrap slide-desk">
            <BrandSlider products={this.state.products.data} ismobile={false}></BrandSlider>
          </div>
          <div className="slider-wrap slide-mob">
            <BrandSlider products={this.state.products.data} ismobile={true}></BrandSlider>
          </div>
        </div>
        <div className="row main-menu">
          <div className="col-md-9">
            <NavTabBar categorys={this.state.category}></NavTabBar>
          </div>
          <div className="col-md-3">
            <div className="search-news">
              <SearchForm
                placeholder={"Search News"}
                handleSubmit={this.handleSubmit}
              />
            </div>
          </div>
        </div>
        <div className="row row-two">
          <div className="col-md-9">
              <NewsSlider news={this.state.news} ads={this.state.ads } ></NewsSlider>  
          </div>
          <div className="col-md-3">
            <SideBarAdCard ads={this.state.ads.sidebar}></SideBarAdCard>
          </div>
        </div>
      </div>
    );
  }
}

export default NewsController;
