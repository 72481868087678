import React, { Component } from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Slider from "react-slick";

import { dateFormat } from "./../config/common";

import noimage from "./../images/dailytime-noimg.png";

class NewsSliderRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news1: props.news1,
      news2: props.news2,
      item2: props.item2,
      item1: props.item1,
    };
  }
  static getDerivedStateFromProps(props, state) {
    return {
      news1: props.news1,
      news2: props.news2,
      item2: props.item2,
      item1: props.item1,
    };
  }
  render() {
    return (
      <>
        {this.state.news1.length >= 3 && this.state.news2.length >= 3 ? (
          <>
            <div className="row  split-row row-three">
              <div className=" col-md-6 block-col1 ">
                <div className="news-wrap-block">
                  <span className="latest-txt">
                    {this.state.news1[0].category}
                  </span>
                  <Card className="latest-block">
                    <Card.Body>
                      <div className="latest-block">
                        <div className="news-list-img">
                          {this.state.news1[0].subcategory ? (
                            <span className="latest-name">
                              {this.state.news1[0].subcategory}
                            </span>
                          ) : (
                            <></>
                          )}
                          <div className="latest-img">
                            <img
                              src={
                                this.state.news1[0].image
                                  ? this.state.news1[0].image
                                  : noimage
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="news-list-cnt">
                          <div className="news-list-top">
                            {/* <span className="list-top-lft">
                                                The Times of India
                                                </span> */}
                            <span className="list-top-rgt">
                              {dateFormat(
                                this.state.news1[0].publish_date,
                                "d M Y"
                              )}
                            </span>
                          </div>

                          <Link
                            to={"/" + this.state.item1 + "/" + this.state.news1[0].slug}
                          >
                            <h2>{this.state.news1[0].title}</h2>
                            <div
                              className="content"
                              dangerouslySetInnerHTML={{
                                __html: this.state.news1[0].content,
                              }}
                            ></div>
                          </Link>
                          <div className="news-list-bottom">
                            <span className="list-btm-lft">
                              <span className="viewcount">
                                {this.state.news1[0].views_count}
                              </span>
                              {/* <span className="like">55</span>
                                                <span className="dislike"></span> */}
                            </span>
                            <span className="list-btm-rgt"> </span>
                          </div>
                        </div>
                      </div>
                      {this.state.news1[1] ? (
                        <div className="latest-block">
                          <div className="news-list-img">
                            {this.state.news1[1].subcategory ? (
                              <span className="latest-name">
                                {this.state.news1[1].subcategory}
                              </span>
                            ) : (
                              <></>
                            )}
                            <div className="latest-img">
                              <img
                                src={
                                  this.state.news1[1].image
                                    ? this.state.news1[1].image
                                    : noimage
                                }
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="news-list-cnt">
                            <div className="news-list-top">
                              {/* <span className="list-top-lft">
                                                    The Times of India
                                                </span> */}
                              <span className="list-top-rgt">
                                {dateFormat(
                                  this.state.news1[1].publish_date,
                                  "d M Y"
                                )}
                              </span>
                            </div>

                            <Link
                              to={"/" + this.state.item1 + "/" + this.state.news1[1].slug}
                            >
                              <h2>{this.state.news1[1].title}</h2>
                              <div
                                className="content"
                                dangerouslySetInnerHTML={{
                                  __html: this.state.news1[1].content,
                                }}
                              ></div>
                            </Link>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="viewcount">
                                  {this.state.news1[1].views_count}
                                </span>
                                {/* <span className="like">55</span>
                                                    <span className="dislike"></span> */}
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div className=" col-md-6 block-col2  ">
                <div className="latest-wrap latest-wrap-row  ">
                  {/* <span className="latest-txt">
                                        {this.state.news1[0].category}
                                    </span> */}
                  <div className="news-slider2-wrap  ">
                    <Slider
                      className={
                        this.state.news1.length > 2
                          ? "news-slider2"
                          : "news-slider2 no-slide"
                      }
                      infinite={false}
                      slidesToShow={1}
                      slidesToScroll={1}
                    >
                      {this.state.news1
                        .filter((i, k) => {
                          return k % 2 === 0;
                        })
                        .map((itm, nid) => {
                          return nid===0?(<></>): (
                            <Card
                              className="latest-block"
                              key={"latest-op-block" + itm.id}
                            >
                              <Card.Body>
                                <div className="latest-block">
                                  <div className="news-list-left">
                                    {itm.subcategory ? (
                                      <span className="latest-name">
                                        {itm.subcategory}
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                    <div className="latest-img">
                                      <img
                                        src={itm.image ? itm.image : noimage}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div className="news-list-right">
                                    <div className="news-list-top">
                                      {/* <span className="list-top-lft">
                                                            The Times of India
                                                        </span> */}
                                      <span className="list-top-rgt">
                                        {dateFormat(itm.publish_date, "d M Y")}
                                      </span>
                                    </div>
                                    <Link to={"/" + this.state.item1 + "/" + itm.slug}>
                                      <h2>{itm.title}</h2>
                                      <div
                                        className="content"
                                        dangerouslySetInnerHTML={{
                                          __html: itm.content,
                                        }}
                                      ></div>
                                    </Link>
                                    <div className="news-list-bottom">
                                      <span className="list-btm-lft">
                                        <span className="viewcount">
                                          {itm.views_count}
                                        </span>
                                        {/* <span className="like">55</span>
                                                            <span className="dislike"></span> */}
                                      </span>
                                      <span className="list-btm-rgt"> </span>
                                    </div>
                                  </div>
                                </div>
                                {this.state.news1[nid * 2 + 1] ? (
                                  <div className="latest-block">
                                    <div className="news-list-left">
                                      {this.state.news1[nid * 2 + 1]
                                        .subcategory ? (
                                        <span className="latest-name">
                                          {
                                            this.state.news1[nid * 2 + 1]
                                              .subcategory
                                          }
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                      <div className="latest-img">
                                        <img
                                          src={
                                            this.state.news1[nid * 2 + 1].image
                                              ? this.state.news1[nid * 2 + 1]
                                                  .image
                                              : noimage
                                          }
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <div className="news-list-right">
                                      <div className="news-list-top">
                                        {/* <span className="list-top-lft">
                                                            The Times of India
                                                            </span> */}
                                        <span className="list-top-rgt">
                                          {dateFormat(
                                            this.state.news1[nid * 2 + 1]
                                              .publish_date,
                                            "d M Y"
                                          )}
                                        </span>
                                      </div>
                                      <Link
                                        to={
                                          "/" +
                                          this.state.item1 +
                                          "/" +
                                          this.state.news1[nid * 2 + 1].slug
                                        }
                                      >
                                        <h2>
                                          {this.state.news1[nid * 2 + 1].title}
                                        </h2>
                                        <div
                                          className="content"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              this.state.news1[nid * 2 + 1]
                                                .content,
                                          }}
                                        ></div>
                                      </Link>
                                      <div className="news-list-bottom">
                                        <span className="list-btm-lft">
                                          <span className="viewcount">
                                            {
                                              this.state.news1[nid * 2 + 1]
                                                .views_count
                                            }
                                          </span>
                                          {/* <span className="like">55</span>
                                                            <span className="dislike"></span> */}
                                        </span>
                                        <span className="list-btm-rgt"> </span>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}

                                <Link to={"/news/" + this.state.item1} className="seemore">
                                  {" "}
                                  See More{" "}
                                </Link>
                              </Card.Body>
                            </Card>
                          );
                        })}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
            <div className="row  split-row row-three">
              <div className=" col-md-6 block-col1 ">
                <div className="news-wrap-block">
                  <span className="latest-txt">
                    {this.state.news2[0].category}
                  </span>
                  <Card className="latest-block">
                    <Card.Body>
                      <div className="latest-block">
                        <div className="news-list-img">
                          {this.state.news2[0].subcategory ? (
                            <span className="latest-name">
                              {this.state.news2[0].subcategory}
                            </span>
                          ) : (
                            <></>
                          )}
                          <div className="latest-img">
                            <img
                              src={
                                this.state.news2[0].image
                                  ? this.state.news2[0].image
                                  : noimage
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="news-list-cnt">
                          <div className="news-list-top">
                            {/* <span className="list-top-lft">
                                                The Times of India
                                                </span> */}
                            <span className="list-top-rgt">
                              {dateFormat(
                                this.state.news2[0].publish_date,
                                "d M Y"
                              )}
                            </span>
                          </div>

                          <Link
                            to={"/" + this.state.item2 + "/" + this.state.news2[0].slug}
                          >
                            <h2>{this.state.news2[0].title}</h2>
                            <div
                              className="content"
                              dangerouslySetInnerHTML={{
                                __html: this.state.news2[0].content,
                              }}
                            ></div>
                          </Link>
                          <div className="news-list-bottom">
                            <span className="list-btm-lft">
                              <span className="viewcount">
                                {this.state.news2[0].views_count}
                              </span>
                              {/* <span className="like">55</span>
                                                <span className="dislike"></span> */}
                            </span>
                            <span className="list-btm-rgt"> </span>
                          </div>
                        </div>
                      </div>
                      {this.state.news2[1] ? (
                        <div className="latest-block">
                          <div className="news-list-img">
                            {this.state.news2[1].subcategory ? (
                              <span className="latest-name">
                                {this.state.news2[1].subcategory}
                              </span>
                            ) : (
                              <></>
                            )}
                            <div className="latest-img">
                              <img
                                src={
                                  this.state.news2[1].image
                                    ? this.state.news2[1].image
                                    : noimage
                                }
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="news-list-cnt">
                            <div className="news-list-top">
                              {/* <span className="list-top-lft">
                                                    The Times of India
                                                </span> */}
                              <span className="list-top-rgt">
                                {dateFormat(
                                  this.state.news2[1].publish_date,
                                  "d M Y"
                                )}
                              </span>
                            </div>

                            <Link
                              to={"/" + this.state.item2 + "/" + this.state.news2[1].slug}
                            >
                              <h2>{this.state.news2[1].title}</h2>
                              <div
                                className="content"
                                dangerouslySetInnerHTML={{
                                  __html: this.state.news2[1].content,
                                }}
                              ></div>
                            </Link>
                            <div className="news-list-bottom">
                              <span className="list-btm-lft">
                                <span className="viewcount">
                                  {this.state.news2[1].views_count}
                                </span>
                                {/* <span className="like">55</span>
                                                    <span className="dislike"></span> */}
                              </span>
                              <span className="list-btm-rgt"> </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div className=" col-md-6 block-col2  ">
                <div className="latest-wrap latest-wrap-row  ">
                  {/* <span className="latest-txt">
                                        {this.state.news2[0].category}
                                    </span> */}
                  <div className="news-slider2-wrap  ">
                    <Slider
                      className={
                        this.state.news2.length > 2
                          ? "news-slider2"
                          : "news-slider2 no-slide"
                      }
                      infinite={false}
                      slidesToShow={1}
                      slidesToScroll={1}
                    >
                      {this.state.news2
                        .filter((i, k) => {
                          return k % 2 === 0;
                        })
                        .map((itm, nid) => {
                          return nid===0?(<></>): (
                            <Card
                              className="latest-block"
                              key={"latest-op-block" + itm.id}
                            >
                              <Card.Body>
                                <div className="latest-block">
                                  <div className="news-list-left">
                                    {itm.subcategory ? (
                                      <span className="latest-name">
                                        {itm.subcategory}
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                    <div className="latest-img">
                                      <img
                                        src={itm.image ? itm.image : noimage}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div className="news-list-right">
                                    <div className="news-list-top">
                                      {/* <span className="list-top-lft">
                                                            The Times of India
                                                        </span> */}
                                      <span className="list-top-rgt">
                                        {dateFormat(itm.publish_date, "d M Y")}
                                      </span>
                                    </div>
                                    <Link to={"/" + this.state.item2 + "/" + itm.slug}>
                                      <h2>{itm.title}</h2>
                                      <div
                                        className="content"
                                        dangerouslySetInnerHTML={{
                                          __html: itm.content,
                                        }}
                                      ></div>
                                    </Link>
                                    <div className="news-list-bottom">
                                      <span className="list-btm-lft">
                                        <span className="viewcount">
                                          {itm.views_count}
                                        </span>
                                        {/* <span className="like">55</span>
                                                            <span className="dislike"></span> */}
                                      </span>
                                      <span className="list-btm-rgt"> </span>
                                    </div>
                                  </div>
                                </div>
                                {this.state.news2[nid * 2 + 1] ? (
                                  <div className="latest-block">
                                    <div className="news-list-left">
                                      {this.state.news2[nid * 2 + 1]
                                        .subcategory ? (
                                        <span className="latest-name">
                                          {
                                            this.state.news2[nid * 2 + 1]
                                              .subcategory
                                          }
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                      <div className="latest-img">
                                        <img
                                          src={
                                            this.state.news2[nid * 2 + 1].image
                                              ? this.state.news2[nid * 2 + 1]
                                                  .image
                                              : noimage
                                          }
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <div className="news-list-right">
                                      <div className="news-list-top">
                                        {/* <span className="list-top-lft">
                                                            The Times of India
                                                            </span> */}
                                        <span className="list-top-rgt">
                                          {dateFormat(
                                            this.state.news2[nid * 2 + 1]
                                              .publish_date,
                                            "d M Y"
                                          )}
                                        </span>
                                      </div>
                                      <Link
                                        to={
                                          "/" +
                                          this.state.item2 +
                                          "/" +
                                          this.state.news2[nid * 2 + 1].slug
                                        }
                                      >
                                        <h2>
                                          {this.state.news2[nid * 2 + 1].title}
                                        </h2>
                                        <div
                                          className="content"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              this.state.news2[nid * 2 + 1]
                                                .content,
                                          }}
                                        ></div>
                                      </Link>
                                      <div className="news-list-bottom">
                                        <span className="list-btm-lft">
                                          <span className="viewcount">
                                            {
                                              this.state.news2[nid * 2 + 1]
                                                .views_count
                                            }
                                          </span>
                                          {/* <span className="like">55</span>
                                                            <span className="dislike"></span> */}
                                        </span>
                                        <span className="list-btm-rgt"> </span>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}

                                <Link to={"/news/" + this.state.item2} className="seemore">
                                  {" "}
                                  See More{" "}
                                </Link>
                              </Card.Body>
                            </Card>
                          );
                        })}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="row  split-row row-three">
            <div className=" col-md-6 block-col1 ">
              <div className="news-wrap-block">
                <span className="latest-txt">
                  {this.state.news1[0].category}
                </span>
                <Card className="latest-block">
                  <Card.Body>
                    <div className="latest-block">
                      <div className="news-list-img">
                        {this.state.news1[0].subcategory ? (
                          <span className="latest-name">
                            {this.state.news1[0].subcategory}
                          </span>
                        ) : (
                          <></>
                        )}
                        <div className="latest-img">
                          <img
                            src={
                              this.state.news1[0].image
                                ? this.state.news1[0].image
                                : noimage
                            }
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="news-list-cnt">
                        <div className="news-list-top">
                          {/* <span className="list-top-lft">
                                                The Times of India
                                                </span> */}
                          <span className="list-top-rgt">
                            {dateFormat(
                              this.state.news1[0].publish_date,
                              "d M Y"
                            )}
                          </span>
                        </div>

                        <Link to={"/" + this.state.item1 + "/" + this.state.news1[0].slug}>
                          <h2>{this.state.news1[0].title}</h2>
                          <div
                            className="content"
                            dangerouslySetInnerHTML={{
                              __html: this.state.news1[0].content,
                            }}
                          ></div>
                        </Link>
                        <div className="news-list-bottom">
                          <span className="list-btm-lft">
                            <span className="viewcount">
                              {this.state.news1[0].views_count}
                            </span>
                            {/* <span className="like">55</span>
                                                <span className="dislike"></span> */}
                          </span>
                          <span className="list-btm-rgt"> </span>
                        </div>
                      </div>
                    </div>
                    {this.state.news1[1] ? (
                      <div className="latest-block">
                        <div className="news-list-img">
                          {this.state.news1[1].subcategory ? (
                            <span className="latest-name">
                              {this.state.news1[1].subcategory}
                            </span>
                          ) : (
                            <></>
                          )}
                          <div className="latest-img">
                            <img
                              src={
                                this.state.news1[1].image
                                  ? this.state.news1[1].image
                                  : noimage
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="news-list-cnt">
                          <div className="news-list-top">
                            {/* <span className="list-top-lft">
                                                    The Times of India
                                                </span> */}
                            <span className="list-top-rgt">
                              {dateFormat(
                                this.state.news1[1].publish_date,
                                "d M Y"
                              )}
                            </span>
                          </div>

                          <Link
                            to={"/" + this.state.item1 + "/" + this.state.news1[1].slug}
                          >
                            <h2>{this.state.news1[1].title}</h2>
                            <div
                              className="content"
                              dangerouslySetInnerHTML={{
                                __html: this.state.news1[1].content,
                              }}
                            ></div>
                          </Link>
                          <div className="news-list-bottom">
                            <span className="list-btm-lft">
                              <span className="viewcount">
                                {this.state.news1[1].views_count}
                              </span>
                              {/* <span className="like">55</span>
                                                    <span className="dislike"></span> */}
                            </span>
                            <span className="list-btm-rgt"> </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <Link to={"/news/" + this.state.item1} className="seemore">
                                  {" "}
                                  See More{" "}
                                </Link>
                  </Card.Body>
                </Card>
              </div>
            </div>
                    {
                         this.state.news2.length > 0?(<div className=" col-md-6 block-col2  ">
                         <div className="latest-wrap latest-wrap-row  ">
                           <span className="latest-txt">
                               {this.state.news2[0].category}
                           </span>
                           <div className="news-slider2-wrap  ">
                             <Slider
                               className={
                                 this.state.news2.length > 2
                                   ? "news-slider2"
                                   : "news-slider2 no-slide"
                               }
                               infinite={false}
                               slidesToShow={1}
                               slidesToScroll={1}
                             >
                               {this.state.news2
                                 .filter((i, k) => {
                                   return k % 2 === 0;
                                 })
                                 .map((itm, nid) => {
                                   return (
                                     <Card
                                       className="latest-block"
                                       key={"latest-op-block" + itm.id}
                                     >
                                       <Card.Body>
                                         <div className="latest-block">
                                           <div className="news-list-left">
                                             {itm.subcategory ? (
                                               <span className="latest-name">
                                                 {itm.subcategory}
                                               </span>
                                             ) : (
                                               <></>
                                             )}
                                             <div className="latest-img">
                                               <img
                                                 src={itm.image ? itm.image : noimage}
                                                 alt=""
                                               />
                                             </div>
                                           </div>
                                           <div className="news-list-right">
                                             <div className="news-list-top">
                                               {/* <span className="list-top-lft">
                                                                       The Times of India
                                                                   </span> */}
                                               <span className="list-top-rgt">
                                                 {dateFormat(itm.publish_date, "d M Y")}
                                               </span>
                                             </div>
                                             <Link to={"/" + this.state.item2 + "/" + itm.slug}>
                                               <h2>{itm.title}</h2>
                                               <div
                                                 className="content"
                                                 dangerouslySetInnerHTML={{
                                                   __html: itm.content,
                                                 }}
                                               ></div>
                                             </Link>
                                             <div className="news-list-bottom">
                                               <span className="list-btm-lft">
                                                 <span className="viewcount">
                                                   {itm.views_count}
                                                 </span>
                                                 {/* <span className="like">55</span>
                                                                       <span className="dislike"></span> */}
                                               </span>
                                               <span className="list-btm-rgt"> </span>
                                             </div>
                                           </div>
                                         </div>
                                         {this.state.news2[nid * 2 + 1] ? (
                                           <div className="latest-block">
                                             <div className="news-list-left">
                                               {this.state.news2[nid * 2 + 1]
                                                 .subcategory ? (
                                                 <span className="latest-name">
                                                   {
                                                     this.state.news2[nid * 2 + 1]
                                                       .subcategory
                                                   }
                                                 </span>
                                               ) : (
                                                 <></>
                                               )}
                                               <div className="latest-img">
                                                 <img
                                                   src={
                                                     this.state.news2[nid * 2 + 1].image
                                                       ? this.state.news2[nid * 2 + 1]
                                                           .image
                                                       : noimage
                                                   }
                                                   alt=""
                                                 />
                                               </div>
                                             </div>
                                             <div className="news-list-right">
                                               <div className="news-list-top">
                                                 {/* <span className="list-top-lft">
                                                                       The Times of India
                                                                       </span> */}
                                                 <span className="list-top-rgt">
                                                   {dateFormat(
                                                     this.state.news2[nid * 2 + 1]
                                                       .publish_date,
                                                     "d M Y"
                                                   )}
                                                 </span>
                                               </div>
                                               <Link
                                                 to={
                                                   "/" +
                                                   this.state.item2 +
                                                   "/" +
                                                   this.state.news2[nid * 2 + 1].slug
                                                 }
                                               >
                                                 <h2>
                                                   {this.state.news2[nid * 2 + 1].title}
                                                 </h2>
                                                 <div
                                                   className="content"
                                                   dangerouslySetInnerHTML={{
                                                     __html:
                                                       this.state.news2[nid * 2 + 1]
                                                         .content,
                                                   }}
                                                 ></div>
                                               </Link>
                                               <div className="news-list-bottom">
                                                 <span className="list-btm-lft">
                                                   <span className="viewcount">
                                                     {
                                                       this.state.news2[nid * 2 + 1]
                                                         .views_count
                                                     }
                                                   </span>
                                                   {/* <span className="like">55</span>
                                                                       <span className="dislike"></span> */}
                                                 </span>
                                                 <span className="list-btm-rgt"> </span>
                                               </div>
                                             </div>
                                           </div>
                                         ) : (
                                           <></>
                                         )}
           
                                         <Link to={"/news/" + this.state.item1} className="seemore">
                                           {" "}
                                           See More{" "}
                                         </Link>
                                       </Card.Body>
                                     </Card>
                                   );
                                 })}
                             </Slider>
                           </div>
                         </div>
                       </div>):(<></>)
                    }
            
          </div>
        )}
      </>
    );
  }
}
export default NewsSliderRow;
